import React , { createContext, useContext, useEffect, useState } from 'react'
import { getToken } from '../fetch';
import Idioma from '../components/Idioma';

const StateContext = createContext();

export const ContextProvider = ({children}) => {
    const [language,setLanguage] = useState('es')
    const [numberFormat,setNumberFormat] = useState(0)
    const [mainCurrency,setMainCurrency] = useState('MXN')
    const [activeMenu, setActiveMenu] = useState(true)
    const [currentMode, setCurrentMode] = useState('Light')
    const [idClient,setIdClient] = useState('')
    const [idRS,setIdRS] = useState('')
    const [clientName,setClientName] = useState('')
    const [clientRSName,setClientRSName] = useState('')
    const [bank,setBankFilter] = useState({name:Idioma.transacciones.configuracion.banco[language]})
    const [account,setAccountFilter] = useState({name:Idioma.transacciones.configuracion.cuentaBanco[language]})
    const [file,setFileFilter] = useState({name:Idioma.transacciones.seleccionarArchivo[language],percentage:''})
    const [showState,setClassificationState] = useState('nonClassified')
    const [dateRange,setDateRangeFilter] = useState([null, null])
    const [startDate, endDate] = dateRange;
    const [accessToken,setAccessToken] = useState()
    const [clientOptions,setClientOptions] = useState([])
    const [clientRSOptions,setClientRSOptions] = useState([])
    const [userInfo,setUserInfo] = useState()
    const [userAccess,setUserAccess] = useState()
    const [contextSubcategoryId,setSubcategoryId] = useState()
    const [contextCategoriesId,setCategoriesId] = useState([])
    const [contextRSID,setRSID] = useState([])
    const [isPay, setIsPay] = useState(1);
    const [sessionID,setSessionIDStorage] = useState()
    const [idPriceStripe, setIdPriceStripe] = useState();

    useEffect(()=>{
        setBankFilter({name:Idioma.transacciones.configuracion.banco[language]})
        setAccountFilter({name: Idioma.transacciones.configuracion.cuentaBanco[language]})
        setFileFilter({name:Idioma.transacciones.seleccionarArchivo[language],percentage:''})
    },[language])
    
    const setCheckIsPay = (isPay) => {
        setIsPay(isPay);
        sessionStorage.setItem('isPay', isPay);
    }

    const setContextBank = (bank) => {
        setBankFilter(bank)
        sessionStorage.setItem('bankFilter',bank)
    }

    const setContextAccount = (account) => {
        setAccountFilter(account)
        sessionStorage.setItem('accountFilter',account)
    }

    const setContextShowState = (value) => {
        setClassificationState(value)
        sessionStorage.setItem('classificationState',value)
    }

    const setIdPrice = ( value ) => {
        setIdPriceStripe(value);
        sessionStorage.setItem('idPriceStripe', value);
    }

    const setContextDateRange = (value) => {
        setDateRangeFilter(value)
        sessionStorage.setItem('dateRangeFilter',JSON.stringify(value))
    }

    const setContextSubcategoryId = (value) => {
        setSubcategoryId(value)
        sessionStorage.setItem('subcategoryIdFilter',value)
    }

    const setContextCategoriesIds = (value) => {
        setCategoriesId(value)
        sessionStorage.setItem('categoriesIdFilter',value)
    }

    const setContextRSID = (value) => {
        setRSID(value)
        sessionStorage.setItem('contextRSID',JSON.stringify(value))
    }

    const setMode = (mode) => {
        setCurrentMode(mode);
        localStorage.setItem('themeMode', mode)
    }

    const setMenu = (menu) => {
        setActiveMenu(menu==='True');
        localStorage.setItem('menuMode', menu)
    }

    const setContextNumberFormat = (value) => {
        setNumberFormat(value)
        sessionStorage.setItem('numberFormat',value)
    }

    const setClient = (client) => {
        setIdRS('')
        setClientRSName('')
        setIdClient(client.id)
        setClientName(client.name)
        setContextNumberFormat(client.numberFormat)
        setLanguage(client.language)
        setMainCurrency(client.currency)
        setUserAccess({
            'access1':client.access1,
            'access2':client.access2,
            'access3':client.access3,
            'access4':client.access4,
            'access5':client.access5,
            'access6':client.access6,
            'access7':client.access7,
            'access8':client.access8,
            'access9':client.access9,
        })
        sessionStorage.setItem('idClient',client.id)
        sessionStorage.setItem('clientName',client.name)
        sessionStorage.setItem('language',client.language)
        sessionStorage.setItem('currency',client.currency)
        sessionStorage.setItem('userAccess',JSON.stringify({
            'access1':client.access1,
            'access2':client.access2,
            'access3':client.access3,
            'access4':client.access4,
            'access5':client.access5,
            'access6':client.access6,
            'access7':client.access7,
            'access8':client.access8,
            'access9':client.access9,
        }))
        setContextBank({name:Idioma.transacciones.configuracion.banco[language]})
        setAccountFilter({name: Idioma.transacciones.configuracion.cuentaBanco[language]})
        setContextDateRange([null,null])
        setFileFilter({name:Idioma.transacciones.seleccionarArchivo[language],percentage:''})
        setContextShowState('nonClassified')
        setContextSubcategoryId()
        sessionStorage.removeItem('idRS')
        sessionStorage.removeItem('contextRSID')
        sessionStorage.removeItem('clientRSName')
        sessionStorage.removeItem('subcategoryIdFilter')
        sessionStorage.removeItem('cashFlowLegend')
        sessionStorage.removeItem('jsonReport')
        sessionStorage.removeItem('jsonPlanningReport')
        sessionStorage.removeItem('jsonPlanningComparison')
    }

    const setRS = (rs) => {
        setIdRS(rs.idRS)
        setClientRSName(rs.rsName)
        setIdClient(rs.id)
        setClientName(rs.name)
        setContextNumberFormat(rs.numberFormat)
        setLanguage(rs.language)
        setMainCurrency(rs.currency)
        setUserAccess({
            'access1':rs.access1,
            'access2':rs.access2,
            'access3':rs.access3,
            'access4':rs.access4,
            'access5':rs.access5,
            'access6':rs.access6,
            'access7':rs.access7,
            'access8':rs.access8,
            'access9':rs.access9,
        })
        console.log('se actualiza RS',rs)
        sessionStorage.setItem('idClient',rs.id)
        sessionStorage.setItem('clientName',rs.name)
        sessionStorage.setItem('idRS',rs.idRS)
        sessionStorage.setItem('clientRSName',rs.rsName)
        sessionStorage.setItem('language',rs.language)
        sessionStorage.setItem('currency',rs.currency)
        sessionStorage.setItem('userAccess',JSON.stringify({
            'access1':rs.access1,
            'access2':rs.access2,
            'access3':rs.access3,
            'access4':rs.access4,
            'access5':rs.access5,
            'access6':rs.access6,
            'access7':rs.access7,
            'access8':rs.access8,
            'access9':rs.access9,
        }))
        setContextBank({name:Idioma.transacciones.configuracion.banco[language]})
        setAccountFilter({name: Idioma.transacciones.configuracion.cuentaBanco[language]})
        setFileFilter({name:Idioma.transacciones.seleccionarArchivo[language],percentage:''})
        setContextShowState('nonClassified')
        setContextDateRange([null,null])
        setRSID([])
        sessionStorage.removeItem('subcategoryIdFilter')
        sessionStorage.removeItem('contextRSID')
        sessionStorage.removeItem('cashFlowLegend')
        sessionStorage.removeItem('jsonReport')
    }
    
    const setToken = (token) => {
        setAccessToken(token)
        sessionStorage.setItem('accessToken',token)
    }

    const setSessionID = (sessionID) => {
        setSessionIDStorage(sessionID)
        sessionStorage.setItem('sessionID',sessionID)
    }

    const setContextFile = (file) => {
        if(file.id){
            setBankFilter({name:Idioma.transacciones.configuracion.banco[language]})
            setAccountFilter({name: Idioma.transacciones.configuracion.cuentaBanco[language]})
        }
        setFileFilter(file)
        sessionStorage.setItem('file',file)
    }

    const setClientOptionsStorage = (clientOptions) => {
        setClientOptions(clientOptions)
        sessionStorage.setItem('clientOptions',JSON.stringify(clientOptions))
    }

    const setClientRSOptionsStorage = (clientRSOptions) => {
         setClientRSOptions(clientRSOptions)
         sessionStorage.setItem('clientRSOPtions',JSON.stringify(clientRSOptions))
    }

    const setUserInformation = (userInfo) => {
        setUserInfo(userInfo)
        sessionStorage.setItem('userInfo',JSON.stringify(userInfo))
    }

    useEffect(() => {
        if(localStorage.getItem('themeMode')){
            let mode = localStorage.getItem('themeMode')
            setCurrentMode(mode)
        }
        if(localStorage.getItem('menuMode')){
            let menu = localStorage.getItem('menuMode')
            setActiveMenu(menu==='True')
        }
        if(sessionStorage.getItem('idPriceStripe')){
            let idPrice = sessionStorage.getItem('idPriceStripe')
            setIdPriceStripe(idPrice)
        }
        if(sessionStorage.getItem('idClient')){
            let idClient = sessionStorage.getItem('idClient')
            let clientName = sessionStorage.getItem('clientName')
            setIdClient(idClient)
            setClientName(clientName)
        }
        if(sessionStorage.getItem('idRS')){
            let idRS = sessionStorage.getItem('idRS')
            let rsName = sessionStorage.getItem('clientRSName')
            setIdRS(idRS)
            setClientRSName(rsName)
        }
        if(sessionStorage.getItem('bankFilter')){
            let bank = sessionStorage.getItem('bankFilter')
            setBankFilter(bank)
        }
        if(sessionStorage.getItem('accountFilter')){
            let account = sessionStorage.getItem('accountFilter')
            setAccountFilter(account)
        }
        if(sessionStorage.getItem('file')){
            let file = sessionStorage.getItem('file')
            setFileFilter(file)
        }
        if(sessionStorage.getItem('classificationState')){
            let showState = sessionStorage.getItem('classificationState');
            setClassificationState(showState)
        }
        if(sessionStorage.getItem('dateRangeFilter')){
            let dateRange = JSON.parse(sessionStorage.getItem('dateRangeFilter'));
            if(dateRange[0]!==null){
                dateRange[0] = new Date(dateRange[0])
            }
            if(dateRange[1]!==null){
                dateRange[1] = new Date(dateRange[1])
            }
            setDateRangeFilter(dateRange)
            console.log('contextDate:',dateRange)
        }
        if(sessionStorage.getItem('clientOptions')){
            let clientOptions = sessionStorage.getItem('clientOptions')
            clientOptions = JSON.parse(clientOptions)
            setClientOptions(clientOptions)
        }
        if(sessionStorage.getItem('clientRSOPtions')){
            let clientRSOPtions = sessionStorage.getItem('clientRSOPtions')
            clientRSOPtions = JSON.parse(clientRSOPtions)
            setClientRSOptions(clientRSOPtions)
        }
        if(sessionStorage.getItem('userInfo')){
            let userInfo = sessionStorage.getItem('userInfo')
            userInfo = JSON.parse(userInfo)
            setUserInfo(userInfo)
        }
        if(sessionStorage.getItem('accessToken')){
            let token = sessionStorage.getItem('accessToken')
            setAccessToken(token)
        }
        if(sessionStorage.getItem('subcategoryIdFilter')){
            let subcategory = sessionStorage.getItem('subcategoryIdFilter')
            setSubcategoryId(subcategory)
        }
        if(sessionStorage.getItem('categoriesIdFilter')){
            let categories = sessionStorage.getItem('categoriesIdFilter')
            setCategoriesId(categories)
        }
        if(sessionStorage.getItem('contextIDRS')){
            let rsID = JSON.parse(sessionStorage.getItem('contextIDRS'))
            setContextRSID(rsID)
        }
        if(sessionStorage.getItem('language')){
            let language = sessionStorage.getItem('language')
            setLanguage(language)
        }
        if(sessionStorage.getItem('currency')){
            let currency = sessionStorage.getItem('currency')
            setMainCurrency(currency)
        }
        if(sessionStorage.getItem('numberFormat')){
            let numberFormat = sessionStorage.getItem('numberFormat')
            setNumberFormat(numberFormat)
        }
        if(sessionStorage.getItem('sessionID')){
            let sessionID = sessionStorage.getItem('sessionID')
            setSessionID(sessionID)
        }
        if(sessionStorage.getItem('userAccess')){
            let userAccess = sessionStorage.getItem('userAccess')
            userAccess = JSON.parse(userAccess)
            setUserAccess(userAccess)
        }else{
            getToken().then((token)=>{setToken(token)})
        }
        
    },[]);

    return (
        <StateContext.Provider
            value={{
                activeMenu,
                setActiveMenu,
                currentMode, setCurrentMode,
                setMode,setMenu,
                idClient,setClient,clientName,
                idRS,setRS,clientRSName,
                bank,setContextBank,
                account,setContextAccount,
                file,setContextFile,
                showState,setContextShowState,
                accessToken,setToken,
                clientOptions,setClientOptionsStorage,
                clientRSOptions,setClientRSOptionsStorage,
                userInfo,setUserInformation,
                userAccess,
                setIdPrice, idPriceStripe,
                setCheckIsPay, isPay,
                startDate,endDate,
                dateRange,
                setContextDateRange,
                setContextSubcategoryId,contextSubcategoryId,
                setContextCategoriesIds,contextCategoriesId,
                contextRSID,setContextRSID,
                language,mainCurrency,
                numberFormat,setContextNumberFormat,
                sessionID,setSessionID
            }}
        >
        {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext);