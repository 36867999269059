import React from 'react'
import Moon from '../assets/Moon'
import Sun from '../assets/Sun'
import Logo from '../assets/Logo';
import { useState, useEffect } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import grupo39 from '../assets/Grupo39.svg'
import Idioma from '../components/Idioma'
import NavbarUserLogo from '../assets/NavbarUserLogo';
import MiniLogo from '../assets/MiniLogo';
import ControlCenterLogo from '../assets/ControlCenterLogo';
import { getToken } from '../fetch';
import NotPay from '../assets/NoPay.png'
import { urlBase } from '../authConfig';
import WarningIcon from '../assets/WarningIcon';

const Navbar = () => {
  const [search, setSearch] = useState('');
  const { setMode, currentMode, clientName, idClient, idRS, clientRSName, clientOptions, clientRSOptions, setClient, setRS, userInfo, language, accessToken, setToken, isPay, setCheckIsPay, setIdPrice } = useStateContext();
  const [openClientOptions,setOpenClientOptions] = useState(false)
  const [clientRSMap,setClientRSMap] = useState({})
  const [order,setOrder] = useState({})
  const [options,setOptions] = useState([])
  const [isAdmin,setIsAdmin] = useState(false);
  const [showNoPay, setShowNoPay] = useState(false);
  
  useEffect(()=>{
    if(clientRSOptions && clientOptions){
        let aux = {}
        let aux2 = [...clientOptions,...clientRSOptions]
        aux2.sort(function(a,b){
          let keyA = a.idClient
          let keyB = b.idClient
          if(keyA > keyB) return 1
          if(keyA < keyB) return -1
          return 0
        })
        for(let i=0;i<clientRSOptions.length;i++){

            if(!aux[clientRSOptions[i].idClient]){
                aux[clientRSOptions[i].idClient] = []
            }
            aux[clientRSOptions[i].idClient] = [...aux[clientRSOptions[i].idClient],clientRSOptions[i]]
        }
        setClientRSMap(aux)
        setOptions(aux2)
    }
    
  },[clientRSOptions,clientOptions])

  useEffect(()=>{
      getToken().then((token)=>{setToken(token)})

      let headers = new Headers()
      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append("Content-Type",'application/json')
      fetch(`${urlBase}/isAdmin`,{
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
          })
      })
      .then((response) => response.json())
      .then((aux) => {
          setIsAdmin(aux.role==='ADMIN')
      });
  },[])

  let location = useLocation();
  let theme = currentMode === 'Dark' ? 'dark' : '';

  const navigate = useNavigate()
  const handleChange = (e) => {
    setSearch(e.target.value);
  }

  const clientChange = (client) => {
    setIdPrice(client.idStripePrice);
    setCheckIsPay(client.paymentValid)
    setOpenClientOptions(false)
    if(client.idRS){
      setRS({
        'id':client.idClient,
        'name':client.client,
        'idRS':client.idRS,
        'rsName':client.rsAlias,
        'access1':client.access1,
        'access2':client.access2,
        'access3':client.access3,
        'access4':client.access4,
        'access5':client.access5,
        'access6':client.access6,
        'access7':client.access7,
        'access8':client.access8,
        'access9':client.access9,
        'language':client.language,
        'currency':client.currency,
        'numberFormat':client.numberFormat,
      })
    }else{
      setClient({
        'id':client.idClient,
        'name':client.client,
        'access1':client.access1,
        'access2':client.access2,
        'access3':client.access3,
        'access4':client.access4,
        'access5':client.access5,
        'access6':client.access6,
        'access7':client.access7,
        'access8':client.access8,
        'access9':client.access9,
        'language':client.language,
        'currency':client.currency,
        'numberFormat':client.numberFormat,
    })
    }
    
    navigate('/selectClient')
  }

  const clientRSChange = (rsOption) => {
    setCheckIsPay(rsOption.paymentValid)
    setOpenClientOptions(false)
    setRS({
      'id':idClient,
      'name':clientName,
      'idRS':rsOption.idRS,
      'rsName':rsOption.rsAlias,
      'access1':rsOption.access1,
      'access2':rsOption.access2,
      'access3':rsOption.access3,
      'access4':rsOption.access4,
      'access5':rsOption.access5,
      'access6':rsOption.access6,
      'access7':rsOption.access7,
      'access8':rsOption.access8,
      'access9':rsOption.access9,
      'language':rsOption.language,
      'currency':rsOption.currency,
      'numberFormat':rsOption.numberFormat,
    })
    navigate('/summary')
  }


  
  const getPath = () => {
    if(location.pathname === '/home'){
      return Idioma.navbar.home[language];
    }else if(location.pathname === '/summary'){
      return Idioma.navbar.summary[language];
    }else if(location.pathname === '/upload'){
      return <span ><Link className='font-normal' to="/classification">{Idioma.navbar.transactions[language]}</Link> &gt;{Idioma.navbar.subirTRansacciones[language]}</span>;
    }else if(location.pathname === '/classification'){
      return Idioma.navbar.transactions[language];
    }else if(location.pathname === '/report'){
      return Idioma.navbar.flujoEfectivo[language];
    }else if(location.pathname === '/balance'){
      return Idioma.navbar.cuentasySaldos[language];
    }else if(location.pathname === '/users'){
      return Idioma.navbar.administracion[language];
    }else if(location.pathname === '/transacciones'){
      return Idioma.navbar.transactions[language];
    }else if(location.pathname === '/subcategories'){
      return Idioma.navbar.catalogoDeCuentas[language];
    }else if(location.pathname === '/rs'){
      return Idioma.navbar.empresas[language];
    }else if(location.pathname === '/planning'){
      return Idioma.navbar.planeacion[language];
    }else if( location.pathname === '/balance/checker' ) {
      return <span><Link to="/balance" className='font-normal'>{Idioma.navbar.cuentasySaldos[language]}</Link> &gt; {Idioma.navbar.verificacionSaldos[language]}</span>;
    }else if( location.pathname === '/summary/entradaEfectivo'){
      return <span><Link to="/summary" className='font-normal'>{Idioma.navbar.summary[language]}</Link> &gt; {Idioma.navbar.entradaDeEfectivo[language]}</span>;
    }else if( location.pathname === '/summary/salidaEfectivo'){
      return <span><Link to="/summary" className='font-normal'>{Idioma.navbar.summary[language]}</Link> &gt; {Idioma.navbar.salidaDeEfectivo[language]}</span>;
    }else if( location.pathname === '/summary/FlujoCash'){
      return <span><Link to="/summary" className='font-normal'>{Idioma.navbar.summary[language]}</Link> &gt; {Idioma.navbar.flujoDeEfectivo[language]}</span>;
    }else if( location.pathname === '/summary/balanceBancos'){
      return <span><Link to="/summary" className='font-normal'>{Idioma.navbar.summary[language]}</Link> &gt; {Idioma.navbar.balanceDeBancos[language]}</span>;
    }else if( location.pathname === '/summary/balanceCredito'){
      return <span><Link to="/summary" className='font-normal'>{Idioma.navbar.summary[language]}</Link> &gt; {Idioma.navbar.saldosTarjetasDeCredito[language]}</span>;
    }else if( location.pathname === '/summary/CajaNeta'){
      return <span><Link to="/summary" className='font-normal'>{Idioma.navbar.summary[language]}</Link> &gt; {Idioma.navbar.cajaNeta[language]}</span>;
    }else if(location.pathname === '/planning/classification'){
      return <span ><Link className='font-normal' to="/planning">{Idioma.navbar.planeacion[language]}</Link> &gt; {Idioma.navbar.editarEscenarios[language]}</span>;
    }else if(location.pathname === '/planning/upload'){
      return <span ><Link className='font-normal' to="/planning">{Idioma.navbar.planeacion[language]}</Link> &gt; {Idioma.navbar.subirPlaneacion[language]}</span>;
    }else if(location.pathname === '/planning/comparison'){
      return <span ><Link className='font-normal' to="/planning">{Idioma.navbar.planeacion[language]}</Link> &gt;{Idioma.navbar.comparacion[language]}</span>;
    }else if(location.pathname === '/control'){
      return Idioma.centroDeControl[language];
    }else if(location.pathname === '/PrivacyPolicy'){
      return Idioma.paginaPrivacyPolicy.titulo[language];
    }else if(location.pathname === '/TyC'){
      return Idioma.paginaTyC.titulo[language];
    }
    return '';
  }

  const getSubtitle = () => {
    if(location.pathname === '/subcategories'){
      return Idioma.navbar.catalogoSubtitulo[language];
    }
    if(location.pathname === '/users'){
      return Idioma.navbar.adminSubtitulo[language];
    }
    if(location.pathname === '/control'){
        return Idioma.centroDeControlSub[language];
    }
    return '';
    
  }

     const errorImage = () => {
        document.getElementById('navbarImage').src = 'https://cicofiles.blob.core.windows.net/webimages/PRUEBA RS ISO.svg'
    }
    const goToControl = () => {
    
        setClient({
            'id':'',
            'name':'',
            'access1':'',
            'access2':'',
            'access3':'',
            'access4':'',
            'access5':'',
            'access6':'',
            'access7':'',
            'access8':'',
            'access9':'',
            'language':'es',
            'currency':'',
            'numberFormat':'',
        })
        navigate('/control')
    }

    const changeToPP = (navigateTo) => {
      setOpenClientOptions(false)
      navigate(navigateTo)
    }

    return (
        <div className={`w-full bg-main-bg p-4 pl-[5%] pb-0 flex items-center ${location.pathname.includes('/summary') ? '' : 'mb-4'} top-0 2xl:pt-10 justify-between 2xl:text-[16px] text-[12px]`}>
            <div>
                <h2 className='font-bold text-[24px] 2xl:text-h2-2xl xxs:text-h9-2xl mobileL:text-h12-2xl mobileL:leading-tight mobileM:text-h12-2xl mobileM:leading-tight mobileS:text-h12-2xl mobileS:leading-tight text-black pt-0.5  min-w-fit '>{getPath()}</h2>
                <p className=' text-p5-2xl leading-[0px] mt-[15px] '>{getSubtitle()}</p>
            </div>
            <div className='flex items-center'>
            {/*
            <button
                type='button'
                onClick={() => {currentMode == 'Light' ? setMode('Dark') : setMode('Light')}}
                >
                <div className='text-md-blue dark:text-md-white ml-12 mb-4'>{currentMode === 'Dark' ? <Sun className='h-10 w-6'/> : <Moon className='h-10 w-6'/>}</div> https://cicofiles.blob.core.windows.net/webimages/${clientName} ISO.svg
            </button>*/}
            <span className='text-right mr-6'>
                <p className='text-h9-2xl xxs:text-h11-2xl mobileL:text-h13-2xl mobileL:leading-tight mobileM:text-h13-2xl mobileM:leading-tight mobileS:text-h13-2xl mobileS:leading-tight'>{userInfo.name}</p>
                <span className='text-neutral-1 font bold text-[12px] 2xl:text-p9-2xl mobileL:text-[9px] mobileL:leading-tight mobileM:text-[9px] mobileM:leading-tight mobileS:text-[9px] mobileS:leading-tight flex justify-end'>{clientName}{clientRSName.length>0 ? ` - ${clientRSName}`:clientRSMap[idClient]?.length>0 ? ` (${Idioma.consolidado[language]})`:''}{!clientName && <MiniLogo/>}</span>
            </span>
                <div className={isPay ? 'flex relative items-center justify-center p-2 bg-secondary rounded-full mr-10' : 'flex relative items-center justify-center p-2 bg-transparent rounded-full mr-10'}>
                <div className='flex flex-col'>
                  <img id='navbarImage' onMouseEnter={() => setShowNoPay(true)} onMouseLeave={() => setShowNoPay(false)} onClick={()=>{
                      setOpenClientOptions((prev)=>!prev)
                  }} src={isPay ? `https://cicofiles.blob.core.windows.net/webimages/${clientName.toUpperCase()} ISO.svg` : NotPay} alt={`Image of ${clientName}`} onError={()=>errorImage()} className='w- h-8 object-contain cursor-pointer' />
                  {showNoPay && !isPay ? (
                    <div className='absolute top-14 bg-red-600 p-2 2xl:w-[398px] w-[300px] right-0 rounded-main animate-warningModal'>
                      <div className='flex justify-between'>
                        <WarningIcon />
                        <h2 className='text-white ml-4'>
                          <span className='font-bold'>
                            Aviso importante:
                          </span> Tu licencia está en riesgo por falta de pago. Para evitar la pérdida de acceso, realiza el pago lo antes posible.
                        </h2>
                      </div>
                    </div>
                  ) : null}
                </div>
                {openClientOptions && 
                <div className='absolute 2xl:w-[398px] w-[300px] max-h-[470px] bg-[#f5f5f5] rounded-main items-start justify-center right-0 top-14'>
                    <div className='w-[90%] max-h-[22rem] rounded-main pb-4 bg-main-bg mx-auto mb-[20px] overflow-y-auto overflow-hidden '>
                    <div className='py-2 mx-2 z-10 flex sticky top-0 bg-main-bg justify-start items-center border-md-divider'>
                        <div className='2xl:px-4 px-2'><NavbarUserLogo/></div>
                        
                        <p className='px-4 font-bold'>{clientName}{clientRSName.length>0 ? ` - ${clientRSName}`:clientRSMap[idClient]?.length>0 ? ` (${Idioma.consolidado[language]})`:''}
                        <br/><span className=' text-p9-2xl'>{userInfo.email}</span></p>
                        </div>
                    
                    {clientRSMap[idClient] && 
                    <>
                    {clientRSMap[idClient].map((rsOption)=> (
                        <>
                        {rsOption.rsAlias!=clientRSName && 
                        <div onClick={()=>{
                            clientRSChange(rsOption)
                            
                        }} className='hover:bg-md-white 2xl:py-2 mx-auto  flex align-middle justify-start px-2 items-center cursor-pointer'>
                            <div className='2xl:px-4 px-2 scale-75 2xl:scale-100'><NavbarUserLogo/></div>
                            <p className='px-4 text-p5-2xl'>{rsOption.rsAlias}</p>
                        </div>
                        }
                        </>
                    ))}
                    </>
                    }
                    {options &&
                    <>
                    
                    <div className='grid grid-cols-1 items-start'>
                    <div className='mt-2 border-b-1 w-[90%] mx-auto  border-md-divider mb-2 '></div>
                    {options.map((option)=>(
                        <>
                            {option.idClient==idClient && !option.idRS && idRS ?
                            <div onClick={()=>{clientChange(option)}} className={`hover:bg-md-white 2xl:py-2   flex align-middle justify-start px-2 items-center cursor-pointer order-first`}>
                                <div className='2xl:px-4 px-2 scale-75 2xl:scale-100'><NavbarUserLogo/></div>
                                <p className='px-4 text-p5-2xl'>{option.client}{option.idClient==idClient && ` (${Idioma.consolidado[language]})`}</p>
                            </div>
                            : option.idClient!=idClient && option.idRS ?
                            <div onClick={()=>{clientChange(option)}} className={`hover:bg-md-white 2xl:py-2 flex align-middle justify-start px-2 items-center cursor-pointer `}>
                                <div className='2xl:px-4 px-2 scale-75 2xl:scale-100'><NavbarUserLogo/></div>

                                <p className='px-4 text-p5-2xl'>{option.client} - {option.rsAlias}</p>
                            </div> 
                            : option.idClient!=idClient ?
                            <div onClick={()=>{clientChange(option)}} className={`hover:bg-md-white 2xl:py-2   flex align-middle justify-start px-2 items-center cursor-pointer`}>
                                <div className='2xl:px-4 px-2 scale-75 2xl:scale-100'><NavbarUserLogo/></div>

                                <p className='px-4 text-p5-2xl'>{option.client}</p>
                            </div> : null}
                        </>
                        ))}
                        

                    {/*clientRSOptions.map((rsOption)=>(
                        <>
                        {rsOption.idClient!=idClient &&
                        <div onClick={()=>{clientChange(rsOption)}} className={`hover:bg-md-white 2xl:py-2 flex align-middle justify-start px-2 items-center cursor-pointer `}>
                        <img className='2xl:px-4 px-2 scale-75 2xl:scale-100' src={grupo39}/>
                        <p className='px-4 font-bold'>{rsOption.client} - {rsOption.rsAlias}</p>
                        </div>}
                        </>
                        ))*/}
                        {isAdmin && <div onClick={()=>{goToControl()}} className={`hover:bg-md-white 2xl:py-2   flex align-middle justify-start px-2 items-center cursor-pointer order-last`}>
                            <div className='2xl:mx-4 mx-2 h-[46px] w-[46px] flex justify-center items-center'><ControlCenterLogo fill="#d3d3d3"/></div>
                            <p className='px-4 text-p5-2xl'>{Idioma.centroDeControl[language]}</p>
                        </div>}
                    </div>
                        </>
                    }
                    </div>
                    <div className='justify-between flex w-[90%] mx-auto text-p9-2xl mb-[14px]' ><button onClick={()=>changeToPP('/PrivacyPolicy')}>Política de Privacidad</button><button onClick={()=>changeToPP('/TyC')}>Términos del servicio</button></div>
                </div>
                    }
                </div>
                
            </div>
        </div>
    )
}

export default Navbar