import React,{ useState, useRef, useEffect } from 'react'
import { Loading, MessageToast, ReusableTable, IndeterminateCheckbox, DinamicDropdown, Styles, Idioma, MessageHandler} from '../components';
import { useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { getToken } from '../fetch';
import moment from 'moment'
import 'moment/locale/es'
import LoadingAnimation from '../animations/Loading.json'
import {AiOutlineArrowRight, AiOutlineCloseCircle, AiOutlineSave} from 'react-icons/ai'
import XLSX from 'sheetjs-style'



import { msalConfig, urlBase } from '../authConfig';
import UploadErrorLogo from '../assets/UploadErrorLogo';
import UploadCorrectLogo from '../assets/UploadCorrectLogo';
import FileUploadLogo from '../assets/FileUploadLogo';
import { Player } from '@lottiefiles/react-lottie-player';
import ThrashCanLogo from '../assets/ThrashCanLogo';
import transaccionesLogo from '../assets/transaccionesLogo';
import { use } from 'echarts/core';
import DownloadIcon from '../assets/DownloadIcon';
import EditIcon from '../assets/EditIcon';
import SearchLogo from '../assets/SearchLogo';
import PreviewIcon from '../assets/PreviewIcon';
import Pencil from '../assets/Pencil';
import XMark from '../assets/XMark';
import WarningLogo from '../assets/WarningLogo';
import RestoreRBinLogo from '../assets/RestoreRBinLogo';

const UploadPlanning = () => {
    const {idClient,accessToken,setToken,setContextFile,setContextShowState,setContextBank,setContextAccount,idRS, language, numberFormat, mainCurrency, sessionID} = useStateContext()
    
    const navigate = useNavigate();

    const isAuthenticated = useIsAuthenticated();

    const genericTemplate={name: Idioma.subirTransacciones.generica[language], headers: 0, date: 0, context: 1, inflow: 2, outflow: 3, movements: -1, creditMovements: -1, extras: [], currency: 4, type: 5, exchange: 6, subcat: -1, notes: -1}

    const [scenario,setScenario] = useState({name:Idioma.planeacion.seleccionaEscenario[language]});
    const [scenarioOptions,setScenarioOptions] = useState([]);
    const [uploadFile,setUploadFile] = useState(null)
    const [template, setTemplate] = useState({name:Idioma.subirTransacciones.dropPlantilla[language]})
    const [templateOptions, setTemplateOptions] = useState([])
    const [templateNewName, setTeplateNewName] = useState(null)
    const [selectedHeader, setSelectedHeader] = useState(0)
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedContext, setSelectedContext] = useState(null)
    const [selectedMovement, setSelectedMovement] = useState(null)
    const [selectedCreditMovement, setSelectedCreditMovement] = useState(null)
    const [selectedInflow, setSelectedInflow] = useState(null)
    const [selectedOutflow, setSelectedOutflow] = useState(null)
    const [selectedExtras, setSelectedExtras] = useState([])
    const [selectedType, setSelectedType] = useState(null)
    const [selectedCurrency, setSelectedCurrency] = useState(null)
    const [selectedExchange, setSelectedExchange] = useState(null)
    const [selectedSubcat, setSelectedSubcat] = useState(null)
    const [selectedNotes, setSelectedNotes] = useState(null)
    const [columnOptions, setColumnOptions] = useState([])
    const [columnValue, setColumnValue] = useState([])
    const [columnsSet, setColumnsSet] = useState(false)
    const [mergedRows, setMergedRows] = useState(null)
    const [extraColumnsDict, setExtraColumnsDict]  = useState({})
    const [extraColumnsKeys, setExtraColumnsKeys]  = useState([])

    const [data,setData] = useState([])
    const [auxData,setAuxData] = useState([])

    const [fileName,setFileName] = useState(Idioma.subirTransacciones.buscar[language])
    const [fileUploaded, setFileUploaded] = useState()

    const inputRef = useRef(null);
    const isGeneric = true;

    const [sendFileLoading,setSendFileLoading] = useState(false);

    const [toastList, setToastList] = useState([])
    const [categorized, setCategorized] = useState(false)


    const [templatePreview, setTemplatePreview] = useState(false)
    const [creatingTemplate, setCreatingTemplate] = useState(false)
    const [editingTemplate, setEditingTemplate] = useState(false)

    const [uploadedTransactions,setUploadedTransactions] = useState({file:-1,total:0,new:0})
    const [unaffectedTransactions,setUnaffectedTransactions] = useState(0)
    const [showTransactionsError,setShowTransactionsError] = useState(false)

    const [isPageLoading,setIsPageLoading] = useState(true)

    const [deleteFileInfo,setDeleteFileInfo] = useState({})
    const [deleteTemplate, setDeleteTemplate] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(true)

    const [templateSize, setTemplateSize] = useState(26)
    const [fileTemplateSize, setFileTemplateSize] = useState(26)
    const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"]
    const rowIndex = Array.from(Array(templateSize).keys())
    const columnIndex = Array.from(Array(templateSize).keys())

    const [idRsOptions, setIdRsOptions] = useState([])
    const [selectedRs, setSelectedRs] = useState({name: Idioma.subirTransacciones.dropEmpresa[language]})

    const [openRemoveFromRecycleBin,setOpenRemoveFromRecycleBin] = useState(false)
    const [openDeletedFiles,setOpenDeletedFiles] = useState(false)
    const [deletedData,setDeletedData] = useState([])
    const [selectedDeletedFiles,setSelectedDeletedFiles] = useState([]) 

    useEffect(() => {
      if(!isAuthenticated){
        navigate('/')
        return
      }
      getToken().then((token)=>{setToken(token)})
      getFileData()
      getScenariosData()
      getColumnCat()
      getRS()
    },[]);

    const getScenariosData = async () => {
      let headers = new Headers()

      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append("Content-Type",'application/json')
      let body = {
        "clientID": idClient,
        logsInfo: {
          origin:'planning/upload',
          sessionID:sessionID 
        },
      }
      if(idRS){
        body.idRS = idRS
      }
      fetch(`${urlBase}/planning/getScenarios`,{
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((aux) => {
          let newData=[]
          for (const property in aux) {
            newData[property] = aux[property]
          }
          for(let i=0; i<newData.length;i++){
            newData[i]['id'] = newData[i]['idScenario']
          }

          setScenarioOptions(newData)
      });
    }

    const getRS = async () => {
      let headers = new Headers()

      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append("Content-Type",'application/json')
      let body = {
        "clientID": idClient,
        logsInfo: {
          origin:'planning/upload',
          sessionID:sessionID 
        },
      }
      if(idRS){
        body.idRS=idRS
      }
      fetch(`${urlBase}/getRS`, {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(body),
          })
          .then((res)=>res.json())
          .then((aux)=>{
            let newRSOptions = []
            aux.map((rs) => {
            newRSOptions.push({id: rs.idRS, name: rs.rsName})
        })
          setIdRsOptions(newRSOptions)
        })
    }

    const getColumnCat = async () => {
      let headers = new Headers()

      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append("Content-Type",'application/json')
      let body = {
        "clientID": idClient,
        logsInfo: {
          origin:'planning/upload',
          sessionID:sessionID 
        },
      }

      if(idRS){
        body.idRS = idRS
      }

      fetch(`${urlBase}/getColumns`,{
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      })
        .then((response) => response.json())
        .then((aux) => {
          let newData=[]
          for (const property in aux[0]) {
              newData[property] = aux[0][property]
          }
          let aux2 = Object.getOwnPropertyNames(aux[0]);
          setExtraColumnsDict(aux[0])
          setExtraColumnsKeys(aux2)
      });
    }

    const getFileData = async () => {

      let headers = new Headers()

      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append("Content-Type",'application/json')
      let body = {
        "clientID": idClient,
        logsInfo: {
          origin:'planning/upload',
          sessionID:sessionID 
        },
      }
      if(idRS){
        body.idRS = idRS
      }
      fetch(`${urlBase}/planning/getFiles`,{
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      })
        .then((response) => response.json())
        .then((data) => {
          let newData=[]
          for(let i=0;i<data.length;i++){
            newData[i]={
              scenario: data[i].name,
              date: data[i].parsedDate ? data[i].parsedDate.slice(0,10) : 'No Date',
              status: data[i].accepted ? 'Aceptado' : 'Denegado',
              file: data[i].inputLocation,
              inputName: data[i].inputName,
              percentage: data[i].total==0 ? 'Error' : data[i].categorized==0 ? 0 : data[i].categorized/data[i].total*100==100 && data[i].categorized!=data[i].total ? 99 : data[i].categorized/data[i].total*100,
              id:data[i].idFile,
              categorized:data[i].categorized,
              total:data[i].total
            }
          }
          if(categorized){
            setCategorized(false)
            let newTotalClassified = newData.filter(file=>file.id===uploadedTransactions.file)[0].categorized
            let newTotal = newData.filter(file=>file.id===uploadedTransactions.file)[0].total
            newToast('success', Idioma.subirTransacciones.exitoSubirTitulo[language], `${Idioma.subirTransacciones.exitoSubirTexto1[language]}${newTotal}${Idioma.subirTransacciones.exitoSubirTexto2[language]}${Idioma.subirTransacciones.clasificadasTexto1[language]}${newTotalClassified}${Idioma.subirTransacciones.clasificadasTexto2[language]}`)
          }
          setData(newData)
          setAuxData(newData)
          setIsPageLoading(false)
        });
    }
    

    const submitForm = async (event) => {
        setSendFileLoading(true)
        event.preventDefault();
        try{
          if(uploadFile.name.split('.').at(-1)!=='xlsx' && uploadFile.name.split('.').at(-1)!=='csv'){
            throw 'Formato'
          }
          const formData = new FormData();
          formData.append('clientID',idClient);
          formData.append('scenarioID',scenario.id)
          formData.append('date',new Date())
          formData.append('bank_file',uploadFile)
          formData.append('planningTemplate',JSON.stringify(template))

          let body = {
            'clientID':idClient,
            "dateFormat":Idioma.formatoFecha[language],
            "planningTemplates": template,
            "mainCurrency": mainCurrency,
            "mergedRows": mergedRows,
            "enviroment": msalConfig.auth.clientId === 'a05b95e4-a7bb-415d-a5fa-47f567e5d94c' ? 'dev' : 'prod',
            logsInfo: {
              origin:'planning/upload',
              sessionID:sessionID 
            },
        }

          if(idRS){
            body.idRS=idRS
            formData.append('idRS',idRS)
          }else{
            body.idRS=selectedRs
            formData.append('idRS',selectedRs)
          }
          
          let headers = new Headers()
          const bearer = `Bearer ${accessToken}`;
          headers.append("Authorization", bearer);
          fetch(`${urlBase}/planning/uploadFile`, {
            method: 'POST',
            body: formData,
            headers: headers
          })
          .then((response) => response.json())
          .then((aux) => {

              body.fileID=aux[0].fileID
            
              headers.append("Content-Type",'application/json')
                fetch(`${urlBase}/planning/processFile`,{
                  method: 'POST',
                  headers: headers,
                  body: JSON.stringify(body)
                })
                .then((response)=>response.json())
                .then((aux)=>{
                  if (aux.message){
                      throw aux.message
                  }
                  setUploadedTransactions(aux)
                  setSendFileLoading(false)
                  let auxMessage = `${Idioma.subirTransacciones.exitoSubirTexto1[language]}${aux.total}${Idioma.subirTransacciones.exitoSubirTexto2[language]}`
                  if(aux.categorized){
                    auxMessage += `${Idioma.subirTransacciones.clasificadasTexto1[language]}${aux.categorizedCount}${Idioma.subirTransacciones.clasificadasTexto2[language]}`
                  }
                  newToast('success', Idioma.subirTransacciones.exitoSubirTitulo[language], auxMessage)
                  
                  resetForm(event)
                })
                .catch(error=> {
                  resetForm(event)
                  setSendFileLoading(false)
                  switch(error[0]){
                    case('Fecha2'):
                      newToast('error', Idioma.transacciones.mensajeError[language], `${Idioma.subirTransacciones.modalDuplicados.errorFecha[language]} ${error[1]}`)
                      break
                    case('Fecha'):
                      newToast('error', Idioma.transacciones.mensajeError[language], `${Idioma.subirTransacciones.modalDuplicados.errorNoFecha[language]}: ${error[1]}`)
                      break
                    case('Concepto'):
                      newToast('error', Idioma.transacciones.mensajeError[language], `${Idioma.subirTransacciones.modalDuplicados.errorConcepto1[language]}${error[1]}${Idioma.subirTransacciones.modalDuplicados.errorConcepto2[language]}`)
                      break
                    case('Movimientos'):
                      newToast('error', Idioma.transacciones.mensajeError[language], `${Idioma.subirTransacciones.modalDuplicados.errorMovimientos1[language]}${error[1]}${Idioma.subirTransacciones.modalDuplicados.errorMovimientos2[language]}`)
                      break
                    case('Movimientos2'):
                      newToast('error', Idioma.transacciones.mensajeError[language], `${Idioma.subirTransacciones.modalDuplicados.errorMovimientosNoValidos1[language]}${error[1]}${Idioma.subirTransacciones.modalDuplicados.errorMovimientosNoValidos2[language]}`)
                      break
                    case('Movimientos3'):
                      newToast('error', Idioma.transacciones.mensajeError[language], `${Idioma.subirTransacciones.modalDuplicados.errorMovimientosNoExistentes1[language]}${error[1]}${Idioma.subirTransacciones.modalDuplicados.errorMovimientosNoExistentes2[language]}`)
                      break
                    case('csv'):
                      newToast('error', Idioma.transacciones.mensajeError[language], `${Idioma.subirTransacciones.modalDuplicados.errorCSV[language]}`)
                      break
                    case('Tipo Moneda'):
                      newToast('error', Idioma.transacciones.mensajeError[language], `${Idioma.planeacion.errorMoneda[language]}${error[1]}`)
                      break
                    case('Tipo Movimiento'):
                      newToast('error', Idioma.transacciones.mensajeError[language], `${Idioma.planeacion.errorTipo[language]}${error[1]}`)
                      break
                    case('Cambio'):
                      newToast('error', Idioma.transacciones.mensajeError[language], `${Idioma.planeacion.errorCambio[language]}${error[1]}`)
                      break
                    case('Inesperado'):
                      newToast('error', Idioma.transacciones.mensajeError[language], `${Idioma.subirTransacciones.modalDuplicados.errorInesperado[language]}`)
                      break
                  }
                })
              //})
          })
        }catch(error){
          console.log(error)
          resetForm(event)
          setSendFileLoading(false)
          if(error==='Formato'){
            newToast('error', Idioma.transacciones.mensajeError[language], Idioma.subirTransacciones.tipoArchivo[language])
          }else{
            newToast('error', Idioma.transacciones.mensajeError[language], Idioma.subirTransacciones.modalDuplicados.errorInesperado[language])
          }
        }
    };

    const resetForm = (event) => {
        event.preventDefault();
        if(!categorized){
          getFileData()
        }
        getScenariosData()
        setScenario({name:Idioma.planeacion.seleccionaEscenario[language]});
        setTemplate({name:Idioma.subirTransacciones.dropPlantilla[language]})
        if(inputRef.current){
          inputRef.current.value = null;
        }
        setFileName(Idioma.subirTransacciones.buscar[language])
        setUploadFile(null)
        setFileUploaded(null)
        setTemplateSize(26)
        setCreatingTemplate(false)
        setEditingTemplate(false)
        setColumnsSet(false)
        setMergedRows(null)
        setSendFileLoading(false)
    }

    const setDeleteFile = (file, deleteFileModal=true) => {
      setOpenDeleteModal(deleteFileModal)
      setDeleteFileInfo(file)
    }

    const closeDeleteModal = () => {
      setDeleteFileInfo({})
    }

    //Move file to recycle bin
    const deleteFile = () => {
      setIsPageLoading(true)
      let headers = new Headers()

      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append("Content-Type",'application/json')

      let body = {
        "clientID": idClient,
        "fileID": deleteFileInfo.id,
        logsInfo: {
          origin:'planning/upload',
          sessionID:sessionID 
        },
      }

      if(idRS){
        body.idRS = idRS
      }

      fetch(`${urlBase}/planning/moveFileToRecycleBin`,{
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      })
        .then((response) => {
          setDeleteFileInfo({})
          getFileData()
        });
    }
    
      const fileTableColumns = React.useMemo(
        () => [
          {
            Header: '',
            accessor: 'status',
            className: 'text-left pb-2.5',
          },
          {
            Header: Idioma.planeacion.escenario[language],
            accessor: 'scenario', // accessor is the "key" in the data
            className: 'rounded-bl-xl pl-1 w-36 text-left pb-2.5',
          },
          {
            Header: Idioma.transacciones.configuracion.fecha[language],
            accessor: 'date', // accessor is the "key" in the data
            className: 'text-left w-40 pb-2.5',
          },
          {
            Header: Idioma.subirTransacciones.archivo[language],
            accessor: 'file', // accessor is the "key" in the data
            className: 'text-left w-40 mr-8 pb-2.5',
          },
          {
            Header: Idioma.subirTransacciones.estado[language],
            accessor: 'percentage',
            className: 'text-left w-40 pb-2.5',
          },
          {
            Header: '',
            accessor: 'delete',
            className: 'text-left pb-2.5',
          },
        ],
        []
      )

      const deletedFileTableColumns = React.useMemo(
        () => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps}) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()}/>
              </div>
            ),
            className: 'text-left text-h10-2xl flex justify-center pr-3.5',
          },
          {
            Header: Idioma.planeacion.escenario[language],
            accessor: 'scenario', // accessor is the "key" in the data
            className: 'rounded-bl-xl pl-1 w-36 text-left pb-2.5',
          },
          {
            Header: Idioma.transacciones.configuracion.fecha[language],
            accessor: 'date', // accessor is the "key" in the data
            className: 'text-left w-40 pb-2.5',
          },
          {
            Header: Idioma.subirTransacciones.archivo[language],
            accessor: 'file', // accessor is the "key" in the data
            className: 'text-left w-40 mr-8 pb-2.5',
          },
          {
            Header: Idioma.subirTransacciones.estado[language],
            accessor: 'frozenPercentage',
            className: 'text-left w-40 pb-2.5',
          },
        ],
        []
      )

      const uploadFiles = (event) => {
        event.preventDefault();
        inputRef.current.click()
      }

      const dropFiles = (event) => {
        event.preventDefault();
        event.stopPropagation();
        handleFileChange(event)
      }

      const handleDrag = (event) => {
        event.preventDefault();
        event.stopPropagation();
      }

      const handleFileChange = event => {
        setIsPageLoading(true)
        let failedUpload = false
        let reader = new FileReader();
        reader.onload = function(e) {
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, {type: 'array'})
          let templateRange = XLSX.utils.decode_range(workbook.Sheets[workbook.SheetNames[0]]['!ref'])
          let merged = workbook.Sheets[workbook.SheetNames[0]]['!merges'] ? workbook.Sheets[workbook.SheetNames[0]]['!merges'] : null
          if(merged){
            let mergedCells = []
            merged.forEach(function(key) {
              mergedCells.push(key.e.r)
              mergedCells.push(key.s.r)
            })
            setMergedRows(mergedCells)
          }else{
            setMergedRows(merged)
          }
          setFileUploaded(workbook.Sheets[workbook.SheetNames[0]])
          if(templateRange.e.c + 1 > 26 && templateRange.e.r + 1 < 10000){
            newToast('warning', Idioma.subirTransacciones.advertenciaTamano[language], Idioma.subirTransacciones.advertenciaMensaje[language])
            setTemplateSize(26)
            setFileTemplateSize(26)
          }else if(templateRange.e.r + 1 > 10000){
            newToast('warning', Idioma.subirTransacciones.advertenciaTamano2[language], Idioma.subirTransacciones.advertenciaMensaje2[language])
            failedUpload = true
          }else{
            setTemplateSize(templateRange.e.c + 1)
            setFileTemplateSize(templateRange.e.c + 1)
          }
          if(failedUpload){
            resetForm(event)
          }
          setIsPageLoading(false)
        }
        setCreatingTemplate(false)
        setEditingTemplate(false)
        let fileInput
        if(event.dataTransfer){
          fileInput = event.dataTransfer.files[0]
        }else{
          fileInput = event.target.files[0]
        }
        reader.readAsArrayBuffer(fileInput)
        setFileName(fileInput.name)
        setUploadFile(fileInput);
      }

      const resetTemplateFields = () => {
        setColumnOptions(new Array(26).fill([{name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}, {name: Idioma.transacciones.configuracion.fecha[language]}, {name: Idioma.transacciones.configuracion.concepto[language]}, {name:Idioma.transacciones.configuracion.abono[language]}, {name:Idioma.transacciones.configuracion.cargo[language]}, {name:Idioma.subirTransacciones.modalPlantilla.cargoAbonos[language]}, {name:Idioma.subirTransacciones.modalPlantilla.abonoCargos[language]}, {name:Idioma.transacciones.configuracion.moneda[language]}, {name:Idioma.transacciones.configuracion.creditoDebito[language]}, {name:Idioma.cuentasYSaldos.tipoCambio[language]}, {name:Idioma.transacciones.configuracion.notas[language]}, {name:Idioma.transacciones.anadirTransaccionModal.subCategoria[language]}].concat(extraColumnsKeys.map((key)=>({name: extraColumnsDict[key]})))))
        setColumnValue(new Array(26).fill({name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}))
      }

      const assingTemplateFields = () => {
        let options = new Array(26).fill([{name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}, {name: Idioma.transacciones.configuracion.fecha[language]}, {name: Idioma.transacciones.configuracion.concepto[language]}, {name:Idioma.transacciones.configuracion.abono[language]}, {name:Idioma.transacciones.configuracion.cargo[language]}, {name:Idioma.subirTransacciones.modalPlantilla.cargoAbonos[language]}, {name:Idioma.subirTransacciones.modalPlantilla.abonoCargos[language]}, {name:Idioma.transacciones.configuracion.moneda[language]}, {name:Idioma.transacciones.configuracion.creditoDebito[language]}, {name:Idioma.transacciones.configuracion.notas[language]}, {name:Idioma.cuentasYSaldos.tipoCambio[language]}, {name:Idioma.transacciones.anadirTransaccionModal.subCategoria[language]}].concat(extraColumnsKeys.map((key)=>({name: extraColumnsDict[key]}))))
        let values = new Array(26).fill({name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]})
        values[template.date] = {name:Idioma.transacciones.configuracion.fecha[language]}
        values[template.context] = {name:Idioma.transacciones.configuracion.concepto[language]}
        if(template.movements===-1 && (template.creditMovements===-1 || template.creditMovements==null)){
          values[template.inflow] = {name:Idioma.transacciones.configuracion.abono[language]}
          values[template.outflow] = {name:Idioma.transacciones.configuracion.cargo[language]}
        }else if(template.movements===-1){
          values[template.creditMovements] = {name:Idioma.subirTransacciones.modalPlantilla.abonoCargos[language]}
        }else{
          values[template.movements] = {name:Idioma.subirTransacciones.modalPlantilla.cargoAbonos[language]}
        }
        for(let i=0;i<template.extras.length;i++){
          if(template.extras[i].hasOwnProperty('accessor')){
            values[template.extras[i].col] = {name:extraColumnsDict[template.extras[i].accessor],col:template.extras[i].col,accessor:template.extras[i].accessor}
          }
        }
        if(template.type!==-1){
          values[template.type] = {name:Idioma.transacciones.configuracion.creditoDebito[language]}
        }
        if(template.currency!==-1){
          values[template.currency] = {name:Idioma.transacciones.configuracion.moneda[language]}
        }
        if(template.exchange!==-1){
          values[template.exchange] = {name:Idioma.cuentasYSaldos.tipoCambio[language]}
        }
        if(template.subcat!==-1){
          values[template.subcat] = {name:Idioma.transacciones.anadirTransaccionModal.subCategoria[language]}
        }
        if(template.notes!==-1){
          values[template.notes] = {name:Idioma.transacciones.configuracion.notas[language]}
        }
        setColumnOptions(options)
        setColumnValue(values)
      }

      const updatePlanningTemplates = (newTemplates) => {
        let headers = new Headers();
          const bearer = `Bearer ${accessToken}`;
          headers.append('Authorization', bearer);
          headers.append('Content-Type', 'application/json');

        let body = {
          'scenarioID': scenario.id,
          'clientID': idClient,
          'scenarioTemplates': JSON.stringify(newTemplates),
          logsInfo: {
            origin:'planning/upload',
            sessionID:sessionID 
          },
        }

        if(idRS){
          body.idRS = idRS
        }

        fetch(`${urlBase}/planning/updateTemplates`,{
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
        }).catch(error=> {
          console.log(error)
        })
      }

      const handleTeamplateCreation = () => {
        let aux = []
        if(templateNewName===null || templateNewName===""){
          aux.push(Idioma.subirTransacciones.modalPlantilla.errorNombre[language])
        }else{
          for(let i=0;i<templateOptions.length;i++){
            if(templateOptions[i].name.toLowerCase()===templateNewName.toLowerCase()){
              aux.push(Idioma.subirTransacciones.modalPlantilla.errorExistente[language])
            }
          }
        }
        if(selectedDate===null){
          aux.push(Idioma.subirTransacciones.modalPlantilla.errorFecha[language])
        }
        if(selectedContext===null){
          aux.push(Idioma.subirTransacciones.modalPlantilla.errorConcepto[language])
        }
        if(selectedInflow===null){
          aux.push(Idioma.subirTransacciones.modalPlantilla.errorAbonos[language])
        }
        if(selectedOutflow===null){
          aux.push(Idioma.subirTransacciones.modalPlantilla.errorCargos[language])
        }
        let auxCredit
        if(selectedCreditMovement===null){
          auxCredit = -1
        }else{
          auxCredit = selectedCreditMovement
        }
        let auxType
        let auxCurr
        let auxSubcat
        let auxExchange
        if(selectedType===null){
          auxType = -1
        }else{
          auxType = selectedType
        }
        if(selectedCurrency===null){
          auxCurr = -1
        }else{
          auxCurr = selectedCurrency
        }
        if(selectedSubcat===null){
          auxSubcat = -1
        }else{
          auxSubcat = selectedSubcat
        }
        if(selectedExchange===null){
          auxExchange = -1
        }else{
          auxExchange = selectedExchange
        }
        let auxNotes
        if(selectedNotes===null){
          auxNotes=-1
        }else{
          auxNotes = selectedNotes
        }
        let extrasValidation = selectedExtras.slice()
        extrasValidation = extrasValidation.filter((element)=>element.hasOwnProperty('accessor')).map((extra)=>({col:extra.col, accessor:extra.accessor}))
        if(aux.length>0){
          aux = aux.join(", ")
          newToast('error', Idioma.subirTransacciones.modalPlantilla.errorTitle[language], aux.charAt(0).toUpperCase() + aux.slice(1))
        }else{
          let newTemplates = []
          if(templateOptions.length>1){
            newTemplates = templateOptions.concat([{'name': templateNewName, 'headers': selectedHeader, 'date': selectedDate, 'context': selectedContext, 'inflow': selectedInflow, 'outflow': selectedOutflow, 'movements': selectedMovement, 'creditMovements': auxCredit, 'extras': extrasValidation, 'type': auxType, 'currency': auxCurr, 'subcat': auxSubcat, 'exchange': auxExchange, 'notes':auxNotes}])
            newTemplates.shift()
            setTemplate(newTemplates[newTemplates.length-1])
          }else{
            newTemplates = [{'name': templateNewName, 'headers': selectedHeader, 'date': selectedDate, 'context': selectedContext, 'inflow': selectedInflow, 'outflow': selectedOutflow, 'movements': selectedMovement, 'creditMovements': auxCredit, 'extras': extrasValidation, 'type': auxType, 'currency': auxCurr, 'subcat': auxSubcat, 'exchange': auxExchange, 'notes':auxNotes}]
            setTemplate(newTemplates[0])
          }
          updatePlanningTemplates(newTemplates)
          newTemplates.unshift(genericTemplate)
          setTemplateOptions(newTemplates)
        }
      }

      const handleTeamplateEdition = () => {
        let aux = []
        if(templateNewName===null || templateNewName===""){
          aux.push(Idioma.subirTransacciones.modalPlantilla.errorNombre[language])
        }else{
          for(let i=0;i<templateOptions.length;i++){
            if(templateOptions[i].name.toLowerCase()===templateNewName.toLowerCase() && template.name!==templateNewName){
              aux.push(Idioma.subirTransacciones.modalPlantilla.errorExistente[language])
            }
          }
        }
        if(selectedDate===null){
          aux.push(Idioma.subirTransacciones.modalPlantilla.errorFecha[language])
        }
        if(selectedContext===null){
          aux.push(Idioma.subirTransacciones.modalPlantilla.errorConcepto[language])
        }
        if(selectedInflow===null){
          aux.push(Idioma.subirTransacciones.modalPlantilla.errorAbonos[language])
        }
        if(selectedOutflow===null){
          aux.push(Idioma.subirTransacciones.modalPlantilla.errorCargos[language])
        }
        let auxCredit
        if(selectedCreditMovement===null){
          auxCredit = -1
        }else{
          auxCredit = selectedCreditMovement
        }
        let auxType
        let auxCurr
        let auxSubcat
        let auxExchange
        if(selectedType===null){
          auxType = -1
        }else{
          auxType = selectedType
        }
        if(selectedCurrency===null){
          auxCurr = -1
        }else{
          auxCurr = selectedCurrency
        }
        if(selectedSubcat===null){
          auxSubcat = -1
        }else{
          auxSubcat = selectedSubcat
        }
        if(selectedExchange===null){
          auxExchange = -1
        }else{
          auxExchange = selectedExchange
        }
        let auxNotes
        if(selectedNotes===null){
          auxNotes=-1
        }else{
          auxNotes = selectedNotes
        }
        if(aux.length>0){
          aux = aux.join(", ")
          newToast('error', Idioma.subirTransacciones.modalPlantilla.errorTitle[language], aux.charAt(0).toUpperCase() + aux.slice(1))
        }else{
          let extrasValidation = selectedExtras.slice()
          extrasValidation = extrasValidation.map((extra)=>(extra.hasOwnProperty('accessor') && {col:extra.col, accessor:extra.accessor}))
          let newTemplates = []
          let templateIndex = templateOptions.findIndex(element => element.name===template.name)
          newTemplates = templateOptions
          newTemplates[templateIndex] = {'name': templateNewName, 'headers': selectedHeader, 'date': selectedDate, 'context': selectedContext, 'inflow': selectedInflow, 'outflow': selectedOutflow, 'movements': selectedMovement, 'creditMovements': auxCredit, 'extras': extrasValidation, 'type': auxType, 'currency': auxCurr, 'subcat': auxSubcat, 'exchange': auxExchange, 'notes': auxNotes}
          setTemplate(newTemplates[templateIndex])
          setTemplateOptions(newTemplates.slice())
          newTemplates.shift()
          updatePlanningTemplates(newTemplates)
        }
      }

      const handleTeamplateDeletion = () => {
        let newTemplates = templateOptions.slice()
        let templateIndex = newTemplates.findIndex(element => element.name===template.name)
        newTemplates.splice(templateIndex, 1)
        setTemplateOptions(newTemplates.slice())
        setTemplate(genericTemplate)
        newTemplates.shift()
        updatePlanningTemplates(newTemplates)
        setDeleteTemplate(false)
        setTemplatePreview(false)
      }

      const handleCreateTemplateView = () => {
        setColumnsSet(false)
        setTemplateSize(26)
        setTeplateNewName(null)
        setSelectedHeader(0)
        setSelectedDate(null)
        setSelectedContext(null)
        setSelectedMovement(null)
        setSelectedCreditMovement(null)
        setSelectedInflow(null) 
        setSelectedOutflow(null)
        setSelectedExtras([])
        setSelectedType(null)
        setSelectedCurrency(null)
        setSelectedExchange(null)
        setSelectedNotes(null)
        setSelectedSubcat(null)
        resetTemplateFields()
        setCreatingTemplate(true)
        setEditingTemplate(false)
      }

      const handleEditTemplateView = () => {
        setColumnsSet(false)
        setTemplateSize(26)
        setTeplateNewName(template.name)
        setSelectedHeader(template.headers)
        setSelectedDate(template.date)
        setSelectedContext(template.context)
        setSelectedMovement(template.movements)
        setSelectedCreditMovement(template.creditMovements !=null ? template.creditMovements : -1)
        setSelectedInflow(template.inflow) 
        setSelectedOutflow(template.outflow)
        setSelectedExtras(template.extras)
        setSelectedType(template.type !=null ? template.type : -1)
        setSelectedSubcat(template.subcat !=null ? template.subcat : -1)
        setSelectedExchange(template.exchange !=null ? template.exchange : -1)
        setSelectedNotes(template.notes !=null ? template.notes : -1)
        assingTemplateFields()
        setCreatingTemplate(false)
        setEditingTemplate(true)
      }

      const handleSelectColumnValue = (col, values, selected) => {
        let aux=values.slice()
        let extraAux = selectedExtras.slice()
        switch(aux[col].name){
          case Idioma.transacciones.configuracion.fecha[language]:
            setSelectedDate(null)
            break
          case Idioma.transacciones.configuracion.concepto[language]:
            setSelectedContext(null)
            break
          case Idioma.transacciones.configuracion.abono[language]:
            setSelectedInflow(null)
            break
          case Idioma.transacciones.configuracion.cargo[language]: 
            setSelectedOutflow(null)
            break
          case Idioma.subirTransacciones.modalPlantilla.cargoAbonos[language]:
            setSelectedMovement(null)
            break
          case Idioma.subirTransacciones.modalPlantilla.abonoCargos[language]:
            setSelectedCreditMovement(null)
            break
          case extraColumnsKeys.map((key)=>extraColumnsDict[key]).find((name)=>name===aux[col].name):
            extraAux.splice(extraAux.indexOf(extraAux.find((extra)=>extraColumnsDict[extra.accessor]===aux[col].name)),1)
            setSelectedExtras(extraAux)
            break
          case Idioma.transacciones.configuracion.creditoDebito[language]:
            setSelectedType(null)
            break
          case Idioma.transacciones.configuracion.moneda[language]:
            setSelectedCurrency(null)
            break
          case Idioma.cuentasYSaldos.tipoCambio[language]:
            setSelectedExchange(null)
            break
          case Idioma.transacciones.anadirTransaccionModal.subCategoria[language]:
            setSelectedSubcat(null)
            break
          case Idioma.transacciones.configuracion.notas[language]:
            setSelectedNotes(null)
            break
        }
        aux[col]=selected
        switch(selected.name){
          case Idioma.transacciones.configuracion.fecha[language]:
            if(selectedDate || selectedDate===0){
              aux[selectedDate]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            }
            setSelectedDate(col)
            break
          case Idioma.transacciones.configuracion.concepto[language]:
            if(selectedContext || selectedContext===0){
              aux[selectedContext]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            }
            setSelectedContext(col)
            break
          case Idioma.transacciones.configuracion.abono[language]:
            if(selectedInflow || selectedInflow===0){
              aux[selectedInflow]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            }
            if(selectedOutflow===-1){
              setSelectedOutflow(null)
            }
            if((selectedMovement || selectedMovement===0) && selectedMovement!==-1){
              aux[selectedMovement]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            }
            if((selectedCreditMovement || selectedCreditMovement===0) && selectedCreditMovement!==-1){
              aux[selectedCreditMovement]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            }
            setSelectedInflow(col)
            setSelectedMovement(-1)
            setSelectedCreditMovement(-1)
            break
          case Idioma.transacciones.configuracion.cargo[language]: 
            if(selectedOutflow || selectedOutflow===0){
              aux[selectedOutflow]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            }
            if(selectedInflow===-1){
              setSelectedInflow(null)
            } 
            if((selectedMovement || selectedMovement===0) && selectedMovement!==-1){
              aux[selectedMovement]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            } 
            if((selectedCreditMovement || selectedCreditMovement===0) && selectedCreditMovement!==-1){
              aux[selectedCreditMovement]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            }
            setSelectedOutflow(col)
            setSelectedMovement(-1)
            setSelectedCreditMovement(-1)
            break
          case Idioma.subirTransacciones.modalPlantilla.cargoAbonos[language]:
            if(selectedMovement || selectedMovement===0){
              aux[selectedMovement]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            }
            if((selectedCreditMovement || selectedCreditMovement===0) && selectedCreditMovement!==-1){
              aux[selectedCreditMovement]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            }
            if((selectedInflow || selectedInflow===0) && selectedInflow!==-1){
              aux[selectedInflow]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            }
            if((selectedOutflow || selectedOutflow===0) && selectedOutflow!==-1){
              aux[selectedOutflow]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            } 
            setSelectedMovement(col)
            setSelectedInflow(-1)
            setSelectedOutflow(-1)
            setSelectedCreditMovement(-1)
            break
          case Idioma.subirTransacciones.modalPlantilla.abonoCargos[language]:
            if(selectedCreditMovement || selectedCreditMovement===0){
              aux[selectedCreditMovement]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            }
            if(selectedMovement || selectedMovement===0){
              aux[selectedMovement]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            }
            if((selectedInflow || selectedInflow===0) && selectedInflow!==-1){
              aux[selectedInflow]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            }
            if((selectedOutflow || selectedOutflow===0) && selectedOutflow!==-1){
              aux[selectedOutflow]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            } 
            setSelectedCreditMovement(col)
            setSelectedMovement(-1)
            setSelectedInflow(-1)
            setSelectedOutflow(-1)
            break
          case extraColumnsKeys.map((key)=>extraColumnsDict[key]).find((name)=>name===selected.name):
            let existingExtra = extraAux.find((extra)=>extraColumnsDict[extra.accessor]===selected.name)
            if(existingExtra){
              aux[existingExtra.col]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
              extraAux.splice(extraAux.indexOf(extraAux.find((extra)=>extraColumnsDict[extra.accessor]===selected.name)),1)
            }
            extraAux.push({name:selected.name,col:col,accessor:extraColumnsKeys.find(key => extraColumnsDict[key] === selected.name)})
            setSelectedExtras(extraAux)
            break
          case Idioma.transacciones.configuracion.creditoDebito[language]:
            if(selectedType || selectedType===0){
              aux[selectedType]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            }
            setSelectedType(col)
            break
          case Idioma.transacciones.configuracion.moneda[language]:
            if(selectedCurrency || selectedCurrency===0){
              aux[selectedCurrency]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            }
            setSelectedCurrency(col)
            break
          case Idioma.cuentasYSaldos.tipoCambio[language]:
            if(selectedExchange || selectedExchange===0){
              aux[selectedExchange]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            }
            setSelectedExchange(col)
            break
          case Idioma.transacciones.anadirTransaccionModal.subCategoria[language]:
            if(selectedSubcat || selectedSubcat===0){
              aux[selectedSubcat]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            }
            setSelectedSubcat(col)
            break
          case Idioma.transacciones.configuracion.notas[language]:
            if(selectedNotes || selectedNotes===0){
              aux[selectedNotes]={name: Idioma.subirTransacciones.modalPlantilla.ninguno[language]}
            }
            setSelectedNotes(col)
            break
        }
        setColumnValue(aux)
      }

      useEffect(()=>{
        if(columnOptions.length>25){
          setColumnsSet(true)
        }
      },[columnOptions])

      useEffect(()=>{
        if(columnsSet && templateSize>25){
          setTemplatePreview(true)
        }
      },[columnsSet])

      useEffect(()=>{
        if(templatePreview){
          setCreatingTemplate(false)
          setEditingTemplate(false)
          setTemplatePreview(false)
        }
      },[fileUploaded, template])

      useEffect(() => {
        if(!openDeleteModal && deleteFileInfo.id){
          deleteFile()
        }
      },[deleteFileInfo])

      const handleTemplatePreview = () => {
        if(templateSize!==fileTemplateSize){
          let aux = fileTemplateSize
          setTemplateSize(aux)
        }
        setCreatingTemplate(false)
        setEditingTemplate(false)
        setTemplatePreview(true)
      }

      const handleScenarioChange = (value) => {
        setScenario(value)
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let body = {
          "clientID": idClient,
          "scenarioID": value.id,
          logsInfo: {
            origin:'planning/upload',
            sessionID:sessionID 
          },
        }

        if(idRS){
          body.idRS = idRS
        }

        fetch(`${urlBase}/planning/getTemplates`,{
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
        })
          .then((response) => response.json())
          .then((aux) => {
            if(aux[0]){
              let scenarioTemplates = JSON.parse(aux[0].scenarioTemplate)
              scenarioTemplates.unshift(genericTemplate)
              setTemplateOptions(scenarioTemplates)
            }else{
              setTemplateOptions([genericTemplate])
            }
        });
      } 

      //Closes recycle bin and empties deleted data array
      const closeRecycleBin = () => {
        setDeletedData([])
        setOpenDeletedFiles(false)
      }

      //Opens recycle bin and gets deleted transactions
      const openRecycleBin = () => {
        setOpenDeletedFiles(true)
      let headers = new Headers()
      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append("Content-Type",'application/json')
      let body = {
        "clientID": idClient,
        "frozen": true,
        logsInfo: {
          origin:'planning/upload',
            sessionID:sessionID
        }
      }
      if(idRS){
        body.idRS = idRS
      }
      fetch(`${urlBase}/planning/getDeletedFiles`,{
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
      })
      .then((response) => response.json())
      .then((aux) => {
        let newData = []
        for(let i=0;i<aux.length;i++){
          newData[i]={
            scenario: aux[i].name,
            date: aux[i].parsedDate ? aux[i].parsedDate.slice(0,10) : 'No Date',
            status: aux[i].accepted ? 'Aceptado' : 'Denegado',
            file: aux[i].inputLocation,
            inputName: aux[i].inputName,
            frozenPercentage: aux[i].total==0 ? 'Error' : aux[i].categorized==0 ? 0 : aux[i].categorized/aux[i].total*100==100 && aux[i].categorized!=aux[i].total ? 99 : aux[i].categorized/aux[i].total*100,
            id:aux[i].idFile,
            categorized:aux[i].categorized,
            total:aux[i].total
          }
        }
        setDeletedData(newData)
      });
      }


      //restore files from the recycle bin
      const restoreFromRecycleBin = async () => {
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

        let allFiles = []
        for(let i=0;i<selectedDeletedFiles.length; i++){
          allFiles[i]=selectedDeletedFiles[i].original.id
        }
        let body = {
          "clientID": idClient,
          "files": allFiles,
          logsInfo: {
            origin:'planning/upload',
      sessionID:sessionID
          }
      }
      if(idRS){
        body.idRS = idRS
      }

        fetch(`${urlBase}/planning/restoreFileFromRecycleBin`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
          /*setErrorMessage(aux.unaffected.length >= 1 ? Idioma.transacciones.transNoActualizadas[language] : '')
          setErrorSign(aux.unaffected.length >= 1)
          setShowTransactionsError(aux.unaffected.length >= 1)
          setUnaffectedTransactions(aux.unaffected)*/
          for(let i=0;i<aux.restored.length;i++){
            setDeletedData(prev => prev.filter(datum => datum.id!==aux.restored[i]))
          }
          getFileData()
        })
        .catch(error=>{
          alert('Ha ocurrido un error')
        })
    }


    //deletes all files from recycle bin
    const emptyRecycleBin = () => {
      let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')  
        let body = {
          "clientID": idClient,
          logsInfo: {
            origin:'planning/upload',
            sessionID:sessionID
          }
        }
        if(idRS){
          body.idRS = idRS
        }
        fetch(`${urlBase}/planning/emptyFileRecycleBin`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
          setDeletedData([])
          setOpenDeletedFiles(false)
        })
        .catch(error=>{
          alert('Ha ocurrido un error')
        })
    }


    //deletes file from recycle bin
    const removeFromRecycleBin = () => {
      let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

        let allFiles = []
        for(let i=0;i<selectedDeletedFiles.length; i++){
          allFiles[i]=selectedDeletedFiles[i].original.id
        }

        let body = {
          "clientID": idClient,
          "files": allFiles,
          logsInfo: {
            origin:'planning/upload',
            sessionID:sessionID
          }
      }
      if(idRS){
        body.idRS = idRS
      }
        fetch(`${urlBase}/planning/deleteFile`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
        /*setShowTransactionsError(aux.unaffected.length >= 1)
        setErrorMessage(aux.unaffected.length >= 1 ? Idioma.transacciones.transNoActualizadas[language] : '')
        setErrorSign(aux.unaffected.length >= 1)

        setUnaffectedTransactions(aux.unaffected)*/
        for(let i=0;i<aux.deleted.length;i++){
          setDeletedData(prev => prev.filter(datum => datum.id!==aux.deleted[i]))
        }
        setOpenRemoveFromRecycleBin(false)
        })
        .catch(error=>{
          alert('Ha ocurrido un error')
        })
    }

      const createCellOfTypeFiles = (cell,row) => {
        if(cell.column.id === 'file'){
          return <div className='relative pr-2'><a className='flex text-left items-center underline hover:text-black' title={cell.value.slice(47)} href={`https://cicofiles.blob.core.windows.net/${cell.value}`}>{cell.value.slice(47,55)}...</a></div>
        }else if(cell.column.id === 'status'){
            if(cell.value === 'Denegado'){
                return <div className='pr-1'>
                    <UploadErrorLogo/>
                </div>
            }else {
                return <div className='pr-1'>
                    <UploadCorrectLogo/>
                </div>
            }
          }else if(cell.column.id === 'selection'){
            return <div className='w-[100%] flex justify-center pr-3'><IndeterminateCheckbox checked={true} {...row.getToggleRowSelectedProps()} /></div>
          }else if(cell.column.id === 'percentage'){
          if(cell.value=='Error'){
            return <div className='font-bold text-xl text-secondary mt-3 '>{cell.value}</div>

          }else{
            return <div className='flex items-center gap-2'>
            <div title={`${cell.row.original.categorized}/${cell.row.original.total}`} className='w-[58px] h-[7px] text-xl overflow-hidden relative rounded-full  bg-secondary-light-2' >
                <div  className={`h-full ${cell.value>=0 && cell.value<25 ? 'bg-[#FF003F]': cell.value>=25 && cell.value<50 ? 'bg-[#FF9700]': cell.value>=50 && cell.value<75 ?'bg-[#FFEC00]' : 'bg-[#00C67E]'}`} style={{"width":`${cell.value}%`,transition: 'width 1s ease-in'}} >
                </div>
                {/*<span onClick={()=>{
                  setFile({
                    'name':cell.row.original.inputName.slice(37),
                    'id':cell.row.original.id,
                    'percentage':cell.row.original.percentage
                  })
                  navigate('/classification')
                }} className='absolute font-bold text-[16px] top-0 right-0 w-full text-center'>{parseInt(cell.value)}</span>*/}
              </div>
              {Math.ceil(cell.value)}%</div>
          }
            
        }else if(cell.column.id === 'frozenPercentage'){
          if(cell.value=='Error'){
            return <div className='font-bold text-xl text-secondary mt-3 '>{cell.value}</div>

          }else{
            return <div className='flex items-center gap-2'>
            <div title={`${cell.row.original.categorized}/${cell.row.original.total}`} className='w-[58px] h-[7px] text-xl overflow-hidden relative rounded-full bg-secondary-light-2'>
                <div  className={`h-full ${cell.value>=0 && cell.value<25 ? 'bg-[#FF003F]': cell.value>=25 && cell.value<50 ? 'bg-[#FF9700]': cell.value>=50 && cell.value<75 ?'bg-[#FFEC00]' : 'bg-[#00C67E]'}`} style={{"width":`${cell.value}%`,transition: 'width 1s ease-in'}} >
                </div>
              </div>
              {Math.ceil(cell.value)}%</div>
          }
        }else if(cell.column.id === 'bank') {
          let bank = cell.value;
          let bankMin = bank.toLowerCase();
          let bankNormal = bank.charAt(0).toUpperCase() + bankMin.slice(1);
          return <div className='pl-1 text-neutral-1 text-left font-bold'>{bankNormal.toUpperCase()}</div>
        }else if(cell.column.id === 'account') {
          return <div className='  text-neutral-1 text-left'>{cell.value}</div>
        }else if(cell.column.id === 'date'){
          return <div className=' text-neutral-1 text-left whitespace-nowrap'>{moment(cell.value).locale(language).format(language==='en' ?'MMM/DD/YYYY':'DD/MMM/YYYY').replace('.','')}</div>
        }else if(cell.column.id === 'delete'){
          return <div className=' w-[15px] flex items-center justify-center'><button className='py-1 scale-[0.5]' onClick={()=>setDeleteFile(cell.row.original)}><ThrashCanLogo/></button></div>
        }
        return <div className=' mt-3 text-left text-neutral-1'>{cell.value}</div>
      }


      const sendFileDisabled = scenario.id && uploadFile && isGeneric;

      if(templatePreview || openDeletedFiles){
        document.body.classList.add('overflow-y-hidden')
      }else{
        document.body.classList.remove('overflow-y-hidden')
      }

      const newToast = (type, title, message) => {
        let toastProperties = []
        toastProperties = {
          'id': toastList.length+1,
          'alertType': type,
          'alertTitle': title,
          'alertMessage': message
        }
        setToastList((prev)=>[...prev, toastProperties])
      }

    return (
        <div className='pl-[5%] 2xl:mt-6 mb-5 mr-10 relative'>
          {isPageLoading ?
                <div className="fixed top-0 right-0 bottom-0 w-screen z-[1000] h-screen">
                    <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z -10 h-screen opacity-50 bg-md-gray'></div>
                    <div className=" h-full w-full flex justify-center items-center">
                        <Player
                            className="w-[20%]"
                            autoplay
                            loop
                            src={LoadingAnimation}
                        ></Player>
                    </div>
                </div>
            :
            null
            }
            {openDeletedFiles && 
              <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
              <div onClick={()=>closeRecycleBin()} className={Styles.modalBackground}>
              </div>
              <div className={Styles.defaultModal}>
                <div className=' w-[800px] relative'>
                <span onClick={()=>closeRecycleBin()} className={Styles.modalXmark}><XMark/></span>

                  <div className='flex items-center mb-[15px]'>
                    <span className='w-[45px]'>
                     <WarningLogo/>
                    </span>
                    <p className='text-[30px] 2xl:text-h5-2xl font-bold'>{Idioma.subirTransacciones.modalPapelera.archivosEliminados[language]}</p>
                  </div>
                  <div className='flex ml-[45px] mb-[20px]'>
                    <p>{Idioma.subirTransacciones.modalPapelera.avisoArchivosPapelera[language]}</p>
                    {/*<button disabled={deletedData.length===0} className={`  ${deletedData.length===0 ? ' ':'text-light-purple-3 underline'}`} onClick={()=>emptyRecycleBin()}>{Idioma.subirTransacciones.modalPapelera.botonVaciarPapelera[language]}</button>*/}
                  </div>
                  <ReusableTable columns={deletedFileTableColumns} data={deletedData} createCellOfType={createCellOfTypeFiles} selectColumn={true} setSelectedRows={setSelectedDeletedFiles}/>
                  <div className='flex items-center justify-between mt-[15px]' >
                    <button onClick={()=>restoreFromRecycleBin()} className='' disabled={selectedDeletedFiles.length==0} title={Idioma.subirTransacciones.modalPapelera.restaurar[language]}>
                      <RestoreRBinLogo fill={selectedDeletedFiles.length==0 && '#ADADAD'}/>
                    </button>
                    <p className={` text-neutral-3 text-center ${selectedDeletedFiles.length>0 ? '':'invisible'}`}>{selectedDeletedFiles.length} {Idioma.subirTransacciones.modalPapelera.archivosSeleccionados[language]}</p>
                    <button onClick={()=>setOpenRemoveFromRecycleBin(true)} disabled={selectedDeletedFiles.length==0} className={`${selectedDeletedFiles.length==0 ? 'bg-gray-6 text-neutral-3':'bg-purple-1 text-white'}  font-bold py-2 px-4 ml-2 rounded-button `}>{Idioma.subirTransacciones.modalPapelera.eliminarDefinitivo[language]}</button>
                  </div>
                  </div>
              </div>
              
            </div>}
            {openRemoveFromRecycleBin && 
            <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1001] h-screen'>
              <div onClick={()=>setOpenRemoveFromRecycleBin(false)} className="fixed top-0 right-0 left-[0%] bottom-0 w-screen z-10 h-screen opacity-[.74] backdrop-brightness-[.15] bg-gray-6 backdrop-blur-[44px]">
              </div>
              <div className='bg-white z-10 absolute top-[calc(40%)] left-[35%] px-[14px] mt-10 py-[28px] w-[500px] rounded-main -translate-x-[50%] -translate-y-[50%]'>
                <p className=' text-h7-2xl text-center'>{Idioma.subirTransacciones.modalPapelera.eliminarPapeleraTitulo[language]}</p>
                <p className=' text-p5-2xl text-center'>{Idioma.subirTransacciones.modalPapelera.eliminarPapeleraTexto1[language]}{selectedDeletedFiles.length} {Idioma.subirTransacciones.modalPapelera.eliminarPapeleraTexto2[language]}</p>
                <div className='flex justify-center items-center mt-[20px]'>
                  <button className='py-2 px-4 ml-2 rounded-button underline text-b4-2xl font-bold' onClick={()=>{setOpenRemoveFromRecycleBin(false)}}>{Idioma.transacciones.desgloseTransaccion.cancelar[language]}</button>
                  <button className={`py-2 px-4 rounded-button text-white bg-purple-1 text-b4-2xl font-bold`} onClick={()=>removeFromRecycleBin()}>{Idioma.subirTransacciones.eliminar[language]}</button>
                </div>
              </div>
            </div>}
            {templatePreview && 
              <>
              {deleteTemplate &&
                <div className='fixed top-0 right-0 bottom-0 w-screen z-[1000] h-screen'>
                  <div onClick={()=>setDeleteTemplate(false)} className=' fixed top-0 right-0 bottom-0 w-screen h-screen backdrop-blur-2xl opacity-80 bg-gray-5'>
                  </div>
                  <div className='bg-white absolute top-[calc(50%)] left-[50%] w-[348px] h-[239px] rounded-main -translate-x-[50%] -translate-y-[50%] p-10 text-p5-2xl'>
                    <p className='mx-auto text-center'>{Idioma.subirTransacciones.accionirreversible[language]}</p>
                    <p className='mx-auto text-center'>{Idioma.subirTransacciones.eliminarPlatilla[language]}</p>
                    <p className='mx-auto text-center'>"{template.name}"</p>
                    <p className='font-bold mx-auto text-center text-p4-2xl'>{Idioma.subirTransacciones.eliminarArchivoPregunta[language]}</p>
                    <div className='flex justify-center items-center mt-5'>
                      <button className={` w-button-4 h-button-4 rounded-button bg-light-purple-3 text-button-4 text-neutral-1 font-bold mr-3`} onClick={handleTeamplateDeletion}>{Idioma.subirTransacciones.eliminar[language]}</button>
                      <button className='w-button-4 h-button-4 rounded-button bg-purple-1 text-button-4 text-white' onClick={()=>setDeleteTemplate(false)}>{Idioma.subirTransacciones.cancelar[language]}</button>
                    </div>
                  </div>
                </div>}
              <div className='fixed top-0 right-0 bottom-0 w-screen z-[999] h-screen'>
                <div onClick={()=>setTemplatePreview(false)} className=' fixed top-0 right-0 left-[15%] bottom-0 w-screen z-10 h-screen opacity-75 bg-gray-5'>
                </div>
                <div className='bg-white p-10 z-10 absolute top-[calc(50%)] left-[57%] shadow-lg w-[1195px] h-[588px]  rounded-main -translate-x-[50%] -translate-y-[50%] '>
                  <div className='w-full relative'>
                    <div className='flex items-center mb-10'>
                      <span className='text-h5-2xl'>{Idioma.subirTransacciones.modalPlantilla.nombre[language]}</span>
                      {creatingTemplate || editingTemplate  ? <input className='ml-3 pl-2 bg-md-gray-light rounded-button text-b4-2xl h-[32px] w-[270px]' placeholder={Idioma.subirTransacciones.modalPlantilla.nueva[language]} onChange={(e)=>setTeplateNewName(e.target.value)} value={templateNewName}></input> : <span className='font-bold ml-3'> {template.name}</span>}
                      <div onClick={()=>setTemplatePreview(false)} className='absolute right-[-20px] top-[-20px] cursor-pointer '>
                        <XMark/>
                      </div>
                    </div>
                    {(creatingTemplate || editingTemplate) ?
                    <div className=' mb-10 flex items-center'>
                      <span className=' text-p7-2xl text-left'>{Idioma.subirTransacciones.modalPlantilla.datos[language]}</span>
                      <span className=' text-p7-2xl text-left ml-auto mr-5'>{Idioma.subirTransacciones.modalPlantilla.dropEncabezado[language]}</span>
                      <DinamicDropdown
                                    options={rowIndex.map(row=>({name: Idioma.subirTransacciones.modalPlantilla.fila[language]+(row+1), row: row}))}
                                    selected={{name: Idioma.subirTransacciones.modalPlantilla.fila[language]+(selectedHeader+1), row: selectedHeader}}
                                    className={`h-button-4 w-button-4 text-p9-2xl`}
                                    setSelected={(row)=>setSelectedHeader(row.row)}
                                  />
                    </div>
                    :
                    <div className=' mb-10 flex items-center'>
                      <span className=' text-p7-2xl text-left'>{Idioma.subirTransacciones.modalPlantilla.vistaPrevia[language]}</span>
                      
                    </div>
                    }
                    <div className=''>
                    <div className='overflow-scroll perfect-scrollbar h-[276px]'>
                    <table className='border-separate border-none max-h-screen text-center'>
                    {creatingTemplate || editingTemplate  ?
                    <>
                      <thead>
                              <tr className="">
                              <th className=" border-1 border-md-gray h-[42px]"><div className='w-[42px]'></div></th>
                              {columnIndex.map(col=>
                                <th className={`border-1 border-md-gray cursor-pointer`}>
                                  <DinamicDropdown
                                    options={columnOptions[col]}
                                    selected={columnValue[col].name===Idioma.subirTransacciones.modalPlantilla.ninguno[language]?{name: alphabet[col]}:columnValue[col]}
                                    className={`min-w-[96px] w-full h-[42px] pl-3 pr-6 text-p9-2xl font-bold `}
                                    tableDropdown={true}
                                    setSelected={(value)=>handleSelectColumnValue(col, columnValue, value)}
                                  />
                                </th>
                              )}
                              </tr>
                      </thead>
                      <tbody>
                        {columnIndex.map(row=>
                          <tr className="2xl:text-[16px] text-[12px] font-bold">
                            <td className={`h-[42px] ${row===selectedHeader ? ' bg-header-md-purple ':'border-1 border-md-gray'}`}>{row+1}</td>
                          {rowIndex.map((col)=>(
                            <td onClick={()=>setSelectedHeader(row)} className={` ${row===selectedHeader ? ' bg-header-md-purple border-none':''} ${(col===selectedDate || col===selectedContext || col===selectedMovement || col===selectedCreditMovement || col===selectedInflow || col===selectedOutflow || selectedExtras.map((extra)=>extra.col).includes(col) || col===selectedType || col===selectedCurrency || col===selectedExchange || col===selectedSubcat || col===selectedNotes) && row>selectedHeader ? ' bg-column-md-purple ':'border-1 border-md-gray'}`}>{fileUploaded && fileUploaded[alphabet[col]+(row+1)] ? (fileUploaded[alphabet[col]+(row+1)]['w'] ? fileUploaded[alphabet[col]+(row+1)]['w'] : fileUploaded[alphabet[col]+(row+1)]['v']) : ''}</td>
                          ))}
                          </tr>
                        )}
                      </tbody>
                    </>
                    :
                    <>
                      <thead>
                              <tr className="2xl:text-[16px] text-[12px] font-bold">
                              <th className="border-1 border-md-gray"><div className='w-[42px]'></div></th>
                              {columnIndex.map(col=>{
                                 if(col===template.date || col===template.context || col===template.movements || col===template.creditMovements || col===template.inflow || col===template.outflow || template.extras.map((extra)=>extra.col).includes(col) || col===template.type || col===template.currency || col===template.exchange || col===template.subcat || col===template.notes){
                                  return <th className={`border-1 border-md-gray h-[42px] w-[96px]`}>{col===template.date ? alphabet[col]+' ('+Idioma.transacciones.configuracion.fecha[language]+')' : col===template.context ? alphabet[col]+' ('+Idioma.transacciones.configuracion.concepto[language]+')' :  template.extras.map((extra)=>extra.col).includes(col) ? alphabet[col]+' ('+extraColumnsDict[template.extras.find((extra)=> extra.col===col).accessor]+')' : col===template.type ? alphabet[col]+' ('+Idioma.transacciones.configuracion.creditoDebito[language]+')': col===template.currency ? alphabet[col]+' ('+Idioma.transacciones.configuracion.moneda[language]+')': col===template.exchange ? alphabet[col]+' ('+Idioma.cuentasYSaldos.tipoCambio[language]+')' : col===template.notes ? alphabet[col]+' ('+Idioma.transacciones.configuracion.notas[language]+')'  : col===template.subcat ? alphabet[col]+' ('+Idioma.transacciones.anadirTransaccionModal.subCategoria[language]+')' : template.movements>-1 ? (col===template.movements ? alphabet[col]+' ('+Idioma.subirTransacciones.modalPlantilla.cargoAbonos[language]+')' : alphabet[col]) : template.creditMovements>-1 ? (col===template.creditMovements ? alphabet[col]+' ('+Idioma.subirTransacciones.modalPlantilla.abonoCargos[language]+')' : alphabet[col]) : (col===template.inflow ? alphabet[col]+' ('+Idioma.transacciones.configuracion.abono[language]+')': col===template.outflow ? alphabet[col]+' ('+Idioma.transacciones.configuracion.cargo[language]+')': alphabet[col])}</th>
                                 }
                              })}
                              </tr>
                      </thead>
                      <tbody>
                        {columnIndex.map(row=>{
                          if(row>=template.headers){
                          return (<tr className="2xl:text-[16px] text-[12px] font-bold">
                            <td className={` w-22 ${row===template.headers ? ' bg-header-md-purple ':'border-1 border-md-gray'}`}>{row+1}</td>
                          {rowIndex.map((col)=>{
                            if(col===template.date || col===template.context || col===template.movements || col===template.creditMovements || col===template.inflow || col===template.outflow || template.extras.map((extra)=>extra.col).includes(col) || col===template.type || col===template.currency || col===template.exchange || col===template.subcat || col===template.notes){
                              return <td className={` h-[42px] w-[96px] ${row===template.headers ? ' bg-header-md-purple border-none':''} ${(col===template.date || col===template.context || col===template.movements || col===template.creditMovements || col===template.inflow || col===template.outflow || template.extras.map((extra)=>extra.col).includes(col) || col===template.type || col===template.currency || col===template.exchange || col===template.subcat || col===template.notes) && row>template.headers ? ' bg-column-md-purple ':'border-1 border-md-gray'}`}>{fileUploaded && fileUploaded[alphabet[col]+(row+1)] ? (fileUploaded[alphabet[col]+(row+1)]['w'] ? fileUploaded[alphabet[col]+(row+1)]['w'] : fileUploaded[alphabet[col]+(row+1)]['v']) : ''}</td>
                            }
                          })}
                          </tr>)}
                        })}
                      </tbody>
                    </>
                    }
                    </table>
                    </div>
                    <div className='pt-5'>
                      <button className='bg-header-md-purple p-2 cursor-default'></button>
                      <span className=' mr-2 pl-2 inline-block align-bottom text-p10-2xl'>{Idioma.subirTransacciones.modalPlantilla.encabezado[language]}</span>
                      <button className='bg-column-md-purple p-2 cursor-default'></button>
                      <span className='pl-2 inline-block align-bottom text-p10-2xl'>{Idioma.subirTransacciones.modalPlantilla.columnas[language]}</span>
                    </div>
                    <div className='pt-5'>
                      <span className='mr-auto text-p10-2xl'>{Idioma.subirTransacciones.modalPlantilla.datosMostrados[language]}</span>
                      {editingTemplate && <div className='absolute right-0 bottom-0 flex items-center'><button onClick={()=>setDeleteTemplate(true)} className=' mt-auto mr-5'><ThrashCanLogo/></button> <button onClick={handleTeamplateEdition} className='bg-light-purple-3 rounded-button font-bold text-neutral-1 text-button-4 h-button-4 w-[160px] mr-5'>{Idioma.transacciones.anadirTransaccionModal.guardar[language]}</button><button className='bg-purple-1 rounded-button text-white text-button-4 h-button-4 w-button-4' onClick={()=>setTemplatePreview(false)} >{Idioma.subirTransacciones.cancelar[language]}</button></div>}
                      {creatingTemplate && <div className='absolute right-0 bottom-0 flex items-center'><button onClick={handleTeamplateCreation} className='bg-light-purple-3 rounded-button font-bold text-neutral-1 text-button-4 h-button-4 w-[160px] mr-5'>{Idioma.transacciones.anadirTransaccionModal.guardar[language]}</button><button className='bg-purple-1 rounded-button text-white text-button-4 h-button-4 w-button-4' onClick={()=>setTemplatePreview(false)} >{Idioma.subirTransacciones.cancelar[language]}</button></div>}
                      {(!editingTemplate && !creatingTemplate) && <div className='absolute right-0 bottom-0 flex items-center'><button onClick={()=>handleEditTemplateView()} className=' mt-auto mr-5 fill-gray-4'><EditIcon/></button> <button className='bg-purple-1 rounded-button text-white text-button-4 h-button-4 w-button-4' onClick={()=>setTemplatePreview(false)} >{Idioma.subirTransacciones.cancelar[language]}</button></div>}
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              </>
            }
            {(deleteFileInfo.id && openDeleteModal) && 
            <div className='fixed top-0 right-0 bottom-0 w-screen z-[1000] h-screen'>
              <div onClick={()=>closeDeleteModal()} className={Styles.modalWarningBackground}>
              </div>
              <div className='bg-white z-10 absolute top-[calc(50%)] left-[50%] w-[448px] h-[258px] rounded-main -translate-x-[50%] -translate-y-[50%] p-10 text-p5-2xl'>
                <p className=' text-center'>{Idioma.subirTransacciones.accionirreversible[language]}</p>
                <p className=' text-center'>{Idioma.subirTransacciones.papeleraArchivoTitulo[language]}<br/> "{deleteFileInfo.file.slice(47)}" {Idioma.subirTransacciones.eliminarArchivoTexto1[language]} {deleteFileInfo.total} {Idioma.subirTransacciones.eliminarArchivoTexto2[language]}</p>
                <p className='font-bold mx-auto text-center text-p4-2xl'>{Idioma.subirTransacciones.eliminarArchivoPregunta[language]}</p>
                <div className='flex justify-center items-center mt-4'>
                  <button className={` w-button-4 h-button-4 rounded-button bg-light-purple-3 text-button-4 text-neutral-1 font-bold mr-3`} onClick={()=>deleteFile()}>{Idioma.subirTransacciones.eliminar[language]}</button>
                  <button className='w-button-4 h-button-4 rounded-button bg-purple-1 text-button-4 text-white' onClick={()=>{closeDeleteModal()}}>{Idioma.subirTransacciones.cancelar[language]}</button>
                </div>
              </div>
            </div>}
            <div className='grid grid-cols-5'>
                <div className={'col-span-2 mt-[30px]'} >
                    <h2 className=' text-h8-2xl font-bold'>{Idioma.subirTransacciones.infoArchivos[language]}</h2>
                    <p className='text-p5-2xl mt-[10px] mb-[70px]'>{Idioma.subirTransacciones.llenarCampos[language]}</p>
                    <form >
                      {idRsOptions.length>0 && !idRS && <div className='grid grid-cols-4 items-center mb-10'>
                          <p className='font-bold text-p7-2xl'>{Idioma.transacciones.configuracion.empresa[language]}</p>
                          <div className='col-span-2'>
                            <DinamicDropdown
                              options={idRsOptions}
                              selected={selectedRs}
                              className={` w-text-field h-button-4`}
                              setSelected={setSelectedRs}
                            />
                          </div>
                        </div>}
                        <div className='grid grid-cols-4 items-center'>
                          <p className='font-bold text-p7-2xl'>{Idioma.planeacion.escenario[language]}</p>
                          <div className='col-span-2'>
                            <DinamicDropdown
                              options={scenarioOptions}
                              selected={scenario}
                              className={` w-text-field h-button-4`}
                              setSelected={handleScenarioChange}
                            />
                          </div>
                        </div>
                        <div className='grid grid-cols-4 items-center my-10' draggable={true} onDrop={dropFiles}  onDragOver={handleDrag}>
                          <p className='font-bold text-p7-2xl'>{Idioma.transacciones.seleccionarArchivo[language]}</p>
                          <div className=' col-span-2 pl-[32px]'>
                            {fileName===Idioma.subirTransacciones.buscar[language] ? <div className='col-span-2 h-[135px] w-[200px] bg-neutral-5 flex-col flex justify-center items-center'>
                              <button className=" w-button-4 h-button-4 bg-neutral-3 rounded-button font-bold text-b4-2xl mb-2" onClick={uploadFiles}>
                                {fileName}
                              </button>
                              <span className=' text-center text-p10-2xl'>{Idioma.subirTransacciones.dropFile1[language]}<br/> {Idioma.subirTransacciones.dropFile2[language]}</span>
                              
                            </div> :
                            <div className='col-span-2 h-[135px] w-[200px] bg-neutral-5 flex-col p-4'>
                              <button className=" flex items-center text-p6-2xl text-left" onClick={uploadFiles}>
                                <div className='h-[30px] w-[30px]'><FileUploadLogo/></div>
                                <span className='ml-2'>{fileName.length > 10 ? fileName.slice(0,8)+'...' : fileName.slice(0,10)}</span>
                              </button>
                            </div>}
                            <input id='selectFile' type="file" style={{'display': 'none'}}  ref={inputRef} onChange={handleFileChange}/>
                          </div>
                        </div>
                        {((template.name===Idioma.subirTransacciones.generica[language] || template.name===Idioma.subirTransacciones.dropPlantilla[language]) && scenario.id) &&
                        <div className=' grid grid-cols-4 items-center'>
                          <p className='font-bold text-p7-2xl'>{Idioma.subirTransacciones.plantillas[language]}</p>
                          <div className='col-span-2'>
                            <a href={require("../assets/GenericoPlaneacion.xlsx")} download="ArchivoGenericoPlaneacion" className='flex items-center'>
                              <DownloadIcon fill="#9191f4"/>
                              <p className='ml-4 whitespace-nowrap text-p8-2xl font-bold text-light-purple-3'>&nbsp;{Idioma.subirTransacciones.plantillaGenerica[language]}</p>
                            </a>
                          </div>
                        </div>
                        }
                        {scenario.id &&
                        <>
                        <div className={`grid grid-cols-4 items-center ${template.name===Idioma.subirTransacciones.generica[language] || template.name===Idioma.subirTransacciones.dropPlantilla[language] ? 'mt-10' : ''}`}>
                          <p className='font-bold text-p7-2xl'>{(template.name!==Idioma.subirTransacciones.generica[language] && template.name!==Idioma.subirTransacciones.dropPlantilla[language]) ? Idioma.subirTransacciones.plantillas[language] : ""}</p>
                          <div className=' col-span-2 cursor-pointer'>
                            <div onClick={()=>handleCreateTemplateView()} className='flex items-center font-bold text-light-purple-3 fill-light-purple-3'>
                              <Pencil/>
                              <p className='ml-4 whitespace-nowrap text-p8-2xl font-bold'>&nbsp;{Idioma.subirTransacciones.crear[language]}</p>
                            </div>
                            </div>
                        </div>
                        {(template.name!==Idioma.subirTransacciones.generica[language] && template.name!==Idioma.subirTransacciones.dropPlantilla[language]) &&
                        <div className='mt-10 grid grid-cols-4 items-center '>
                        <p></p>
                        <div className=' col-span-2 cursor-pointer'>
                            <div onClick={()=>handleEditTemplateView()} className='flex items-center font-bold text-light-purple-3 fill-light-purple-3'>
                              <EditIcon/>
                              <p className='ml-4 whitespace-nowrap text-p8-2xl font-bold'>&nbsp;{Idioma.subirTransacciones.editar[language]}</p>
                            </div>
                          </div>
                        </div>
                        }
                        </>
                        }
                        <div className='grid grid-cols-4 items-center mt-10'>
                          <p className='font-bold text-p7-2xl'>{Idioma.subirTransacciones.plantillaUtilizar[language]}</p>
                          <div className='col-span-2'>
                            <DinamicDropdown
                              options={templateOptions}
                              selected={template}
                              setSelected={setTemplate}
                              className={` w-text-field h-button-4 col-span-2`}
                              disabled={!scenario.id}
                            />
                          </div>
                        </div>
                        {(scenario.id && template.name!==Idioma.subirTransacciones.dropPlantilla[language]) &&
                        <div className='grid grid-cols-4 items-center mt-10'>
                          <p></p>
                          <div className=' col-span-2 cursor-pointer'>
                            <div onClick={()=>handleTemplatePreview()} className='flex items-center font-bold text-light-purple-3'>
                              <PreviewIcon/>
                              <p className='ml-4 whitespace-nowrap text-p8-2xl font-bold'>&nbsp;{Idioma.subirTransacciones.previa[language]}</p>
                            </div>
                          </div>
                        </div>
                        }  
                        <div className='mt-10 grid grid-cols-4'>
                        <p></p>
                        <div className='col-span-2'>
                              <button className={` rounded-button w-button-4 h-button-4 text-b4-2xl mr-[15px]  ${sendFileLoading || !sendFileDisabled || template.name===Idioma.subirTransacciones.dropPlantilla[language] ? 'bg-gray-5 text-white':'bg-light-purple-3 font-bold'}`} disabled={sendFileLoading || !sendFileDisabled || template.name===Idioma.subirTransacciones.dropPlantilla[language]} onClick={submitForm}>
                              {sendFileLoading ? <div className='invisible xl:visible  top-0 mt-1'><Loading/></div>:null}
                              {sendFileLoading ? '':Idioma.subirTransacciones.subir[language]}
                              </button>
                              <button className='bg-secondary rounded-button text-white text-b4-2xl w-button-4 h-button-4 font-bold border-solid border-md-gray border-1'
                              onClick={resetForm}
                              >
                                {Idioma.subirTransacciones.cancelar[language]}
                              </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className={'col-span-3 mt-[105px]'}>
                  <div className=' rounded-main shadow-medium-elevation w-[629px] max-h-[508px] p-10 ml-20'>
                    <p className='pb-10 text-h8-2xl'>{Idioma.subirTransacciones.filesRecientes[language]}</p>
                    <div className='overflow-auto max-h-[275px] min-h-[200px]'>
                      <ReusableTable columns={fileTableColumns} data={data} createCellOfType={createCellOfTypeFiles}/>
                    </div>
                    <div className='flex items-center'>
                      <button onClick={()=>openRecycleBin(true)} className='flex  items-center mt-10'><ThrashCanLogo fill="#000"/><p className='2xl:text-[18px] ml-1 underline -mb-0.5 font-normal'>{Idioma.transacciones.papelera[language]}</p></button>
                      <div onClick={()=>navigate('/planning/classification')} className='cursor-pointer w-[200px] h-[42px] bg-light-purple-3 rounded-button font-bold text-white text-b4-2xl flex items-center justify-center mt-10 ml-auto'>
                        {Idioma.planeacion.modalEditar.titulo[language]}
                      </div>
                    </div>
                    </div>
                      <div className='flex mb-4 mt-1 '>
                        <div className={`${showTransactionsError ? '':'hidden'} relative bg-md-light-gray rounded-main 2xl:w-72 w-48 p-2`}>
                        <AiOutlineCloseCircle onClick={()=>setShowTransactionsError(false)} className='absolute right-0.5 top-0.5 text-white cursor-pointer'/>
                          <AiOutlineArrowRight onClick={()=>setShowTransactionsError(false)} className='absolute bottom-0 right-0 text-white cursor-pointer' />
                          <p className='text-white 2xl:text-[12px] text-[10px]'>{Idioma.transacciones.mensajeError[language]}</p>
                          <div className='flex'>
                            <p className='text-white 2xl:text-[28px] text-[16px] font-bold'>{unaffectedTransactions.length}</p>
                            <p className='2xl:text-[12px] text-[10px] ml-2 text-white'>{Idioma.transacciones.bloqueError1[language]}<br/> {Idioma.transacciones.bloqueError2[language]}</p>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
            <MessageHandler toastList={toastList} setToastList={setToastList}/>
        </div>
    )
}

export default UploadPlanning